import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { scrollToTop } from "../sections/ScrollToTop";
import { useAuth } from "../../Context/AuthContext";
import AxiosInstance from "../../Api/AxiosInstance";
import { FaTrash } from "react-icons/fa";
import Loader from "./Loader";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Cart = () => {
  const { user, handleLogin } = useAuth();
  const [cartdata, setCartdata] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [cart_total_details, setCartDetail] = useState([]);
  const [editingCartId, setEditingCartId] = useState(null);
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return true;
    }
    setPasswordType("password");
    return true;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchProduct = async () => {
    try {
      const response = await AxiosInstance.post("cart-lists");
      if (response.data.status == "Authorization Token not found") {
        navigate("/login");
        scrollToTop();
      }
      setCartdata(response.data.data.items);
      setLoading(false);
      setCartDetail(response.data.data.cart_total_details);
    } catch (error) {}
  };

  useEffect(() => {
    fetchProduct();
  }, [cartdata.length]);

  const quantityCount = async (data) => {
    try {
      setLoading(true);
      const response = await AxiosInstance.post("update-cart-lists", {
        cart_id: data[0],
        quantity: data[1],
      });
      setEditingCartId(null);
      document.dispatchEvent(new Event("itemAddedToCart"));
      fetchProduct();
    } catch (error) {}
  };

  const handleQuantityChange = (e, cart_id) => {
    const newQuantity = e.target.value === "" ? 0 : parseInt(e.target.value);
    setCartdata((prevCartdata) =>
      prevCartdata.map((item) =>
        item.cart_id === cart_id ? { ...item, quantity: newQuantity } : item
      )
    );
    setEditingCartId(cart_id);
  };

  const checkOut = () => {
    if (user) {
      scrollToTop();
      navigate("/checkout");
    } else {
      setShow(true);
    }
  };

  const submit = async (data) => {
    try {
      const response = await AxiosInstance.post("login", {
        sponsor_id: data.sponsor_id,
        password: data.password,
      });
      if (response.data.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.access_token);
        handleLogin();
      }
      if (response.data.success == "1") {
        navigate("/checkout");
        scrollToTop();
        window.location.reload();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="wrapper">
        <div className="blank_header trans"></div>
        <main className="main-content">
          <nav aria-label="breadcrumb" className="breadcrumb-style1 pt-5 pb-5">
            <div className="container">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <Link onClick={scrollToTop} to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Cart
                </li>
              </ol>
            </div>
          </nav>

          {loading ? (
            <Loader />
          ) : cartdata.length ? (
            <section className="cart-middle-section section-space">
              <div className="container">
                <div className="shopping-cart-form table-responsive">
                  <form action="#" method="post">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th className="product-remove">&nbsp;</th>
                          <th className="product-thumbnail">&nbsp;</th>
                          <th className="product-name">Product</th>
                          <th className="product-price">Price</th>
                          <th className="product-quantity">Quantity</th>
                          <th className="product-subtotal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartdata?.map((item) => (
                          <tr className="tbody-item" key={item?.id}>
                            <td className="product-remove">
                              <a
                                className="remove"
                                onClick={() =>
                                  quantityCount([item?.cart_id, 0])
                                }
                              >
                                ×
                              </a>
                            </td>
                            <td className="product-thumbnail">
                              <div className="thumb">
                                <Link
                                  onClick={scrollToTop}
                                  to={`/product/${item?.slug}`}
                                >
                                  <img src={item?.file_url[0]} alt="" />
                                </Link>
                              </div>
                            </td>
                            <td className="product-name">
                              <Link
                                className="title"
                                onClick={scrollToTop}
                                to={`/product/${item?.slug}`}
                              >
                                {item?.title} {item?.variant_name && (item?.variant_name)}
                              </Link>
                            </td>
                            <td className="product-price">
                              <span className="price">
                                ₹{item?.item_price?.toFixed(2)}
                              </span>
                            </td>
                            <td className="product-quantity">
                              <div className="pro-qty">
                                {item?.quantity > 1 && (
                                  <div
                                    className="dec qty-btn"
                                    onClick={() =>
                                      quantityCount([
                                        item?.cart_id,
                                        item?.quantity - 1,
                                      ])
                                    }
                                  >
                                    -
                                  </div>
                                )}
                                {item?.quantity <= 1 && (
                                  <div
                                    className="dec delete-icon qty-btn"
                                    onClick={() =>
                                      quantityCount([item?.cart_id, 0])
                                    }
                                  >
                                    <FaTrash />
                                  </div>
                                )}
                                <input
                                  type="text"
                                  className="quantity"
                                  title="Quantity"
                                  value={
                                    item?.quantity == "" ? 0 : item?.quantity
                                  }
                                  onChange={(e) =>
                                    handleQuantityChange(e, item.cart_id)
                                  }
                                />
                                <div
                                  className="inc qty-btn"
                                  onClick={() =>
                                    quantityCount([
                                      item?.cart_id,
                                      item?.quantity + 1,
                                    ])
                                  }
                                >
                                  +
                                </div>
                              </div>
                              {editingCartId === item.cart_id && (
                                <button
                                  className="btn btn-primary m-2"
                                  onClick={() =>
                                    quantityCount([item.cart_id, item.quantity])
                                  }
                                >
                                  Update
                                </button>
                              )}
                            </td>
                            <td className="product-subtotal">
                              <span className="price">
                                ₹{item?.sub_total?.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </form>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6"></div>
                  <div className="col-12 col-lg-6">
                    <div className="cart-totals-wrap">
                      <h2 className="title">Cart total</h2>
                      <table>
                        <tbody>
                          <tr className="order-total">
                            <th>SUBTOTAL</th>
                            <td>
                              <span className="amount">
                                ₹{cart_total_details?.sub_total?.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr className="order-total">
                            <th>bv total</th>
                            <td>
                              <span className="amount">
                                {cart_total_details?.bv_total?.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr className="shipping-totals">
                            <th>Tax</th>
                            <td>
                              <ul className="shipping-list">
                                {cart_total_details?.igst_status == 1 ? (
                                  <li className="radio">
                                    <label for="radio1">
                                      IGST:{" "}
                                      <span className="amount">
                                        ₹{cart_total_details?.igst?.toFixed(2)}
                                      </span>
                                    </label>
                                  </li>
                                ) : (
                                  <>
                                    <li className="radio">
                                      <label for="radio1">
                                        SGST:{" "}
                                        <span className="amount">
                                          ₹
                                          {cart_total_details?.sgst?.toFixed(2)}
                                        </span>
                                      </label>
                                    </li>
                                    <li className="radio">
                                      <label for="radio1">
                                        CGST:{" "}
                                        <span className="amount">
                                          ₹
                                          {cart_total_details?.cgst?.toFixed(2)}
                                        </span>
                                      </label>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </td>
                          </tr>
                          <tr className="order-total">
                            <th>Total</th>
                            <td>
                              <span className="amount">
                                ₹{cart_total_details?.total?.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="text-end">
                        <button
                          onClick={() => checkOut()}
                          className="checkout-button"
                        >
                          Proceed to checkout
                        </button>
                      </div>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="md"
                        centered
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                        >
                          <span
                            className="fa fa-close"
                            onClick={handleClose}
                          ></span>
                        </button>
                        <section className="m-5">
                          <p className="text-20">
                            If you have shopped with us before, please enter
                            your details below. If you are a new customer,
                            please proceed to the Billing section.
                          </p>
                          <div className="container">
                            <div className="row mb-n8">
                              <div className="col-lg-12 mb-8">
                                <div className="my-account-item-wrap">
                                  <h3 className="title">Login</h3>
                                  <div className="my-account-form">
                                    <form onSubmit={handleSubmit(submit)}>
                                      <div className="form-group mb-6">
                                        <label htmlFor="login_username">
                                          Sponsor ID <sup>*</sup>
                                        </label>
                                        <input
                                          type="text"
                                          id="login_username"
                                          placeholder="Sponsor ID"
                                          {...register("sponsor_id", {
                                            required: "Sponsor ID is required",
                                          })}
                                        />
                                        <p
                                          className="mt-1"
                                          style={{
                                            color: "red",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {errors.sponsor_id?.message}
                                        </p>
                                      </div>

                                      <div className="form-group mb-6 pwd-eye">
                                        <label htmlFor="login_pwsd">
                                          Password <sup>*</sup>
                                        </label>
                                        <input
                                          type={passwordType}
                                          id="login_pwsd"
                                          placeholder="Password"
                                          {...register("password", {
                                            required: "Password is required",
                                            minLength: {
                                              value: 6,
                                              message:
                                                "Password must be at least 6 characters long",
                                            },
                                          })}
                                        />
                                        <div
                                          onClick={() =>
                                            togglePassword("password")
                                          }
                                        >
                                          {passwordType === "password" ? (
                                            <AiOutlineEye className="pass-view" />
                                          ) : (
                                            <AiOutlineEyeInvisible className="pass-view" />
                                          )}
                                        </div>

                                        <p
                                          className="mt-1"
                                          style={{
                                            color: "red",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {errors.password?.message}
                                        </p>
                                      </div>

                                      <div className="form-group d-flex align-items-center mb-14">
                                        <div className="form-check d-flex">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="remember_pwsd"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="remember_pwsd"
                                          >
                                            Remember Me
                                          </label>
                                        </div>

                                        <button
                                          className="btn ml-auto"
                                          type="submit"
                                        >
                                          Login
                                        </button>
                                      </div>
                                    </form>
                                    <div className="form-group">
                                      <Link
                                        className="lost-password"
                                        onClick={() => {
                                          scrollToTop();
                                          setShow(false);
                                        }}
                                        to="/forgot_password"
                                      >
                                        <u>Lost your Password?</u>
                                      </Link>
                                    </div>
                                    <div className="form-group">
                                      <Link
                                        className="lost-password"
                                        onClick={() => {
                                          scrollToTop();
                                          setShow(false);
                                        }}
                                        to="/register"
                                      >
                                        <u>
                                          New to SmmartLife? Create an account
                                        </u>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div>
              <br />
              <h3 className="text-center">Your cart is empty</h3>
              <br />
              <div className="text-center">
                <button
                  onClick={() => navigate("/shop")}
                  className="btn ml-auto"
                >
                  Return to Shop
                </button>
              </div>
              <br />
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default Cart;
