import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { scrollToTop } from './ScrollToTop';
import AxiosInstance from '../../Api/AxiosInstance';
import { toast } from 'react-toastify';
import { Autoplay, Navigation, Virtual, Pagination } from 'swiper/modules';
import { Tooltip } from 'antd';

const Product = ({ title, product_data, field, contact_us_details = null }) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(product_data);
    }, [product_data]);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setQuantity(1) }
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1)
    const [cratpopup, setCartpopup] = useState(false)
    const navigate = useNavigate()
    const handleShow = (item) => {
        setSelectedProduct(item);
        setShow(true);
    };
    const updateWhishlistStatus = (index, newStatus, type_id) => {
        setItems(prevProductData => {
            const statusKey = type_id === 1 ? 'cart_status' : 'wishlist_status';
            const updatedProductData = [...prevProductData];
            updatedProductData[index] = {
                ...updatedProductData[index],
                cart_whishlist: {
                    ...updatedProductData[index].cart_whishlist,
                    [statusKey]: newStatus
                }
            };
            type_id === 1 ? document.dispatchEvent(new Event('itemAddedToCart')) : document.dispatchEvent(new Event('itemAddedToWishlist'));
            return updatedProductData;
        });
    };
    function addToCart(id, price, qty, index, type_id) {
        const obj = { 'product_id': id, 'price': price, 'quantity': qty, 'sub_total': price * qty, 'type_id': type_id }
        AxiosInstance.post('add-to-cart', obj)
            .then((response) => {
                if (response.data.success) {
                    setShow(false)
                    const index = items.findIndex(item => item?.id === id);

                    if (type_id == 2) {

                        toast.success('Item added to wishlist successfully.', {
                            autoClose: 3000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        if (index !== -1) {
                            updateWhishlistStatus(index, 1, 2);
                        }
                    } else {
                        setQuantity(1)
                        if (index !== -1) {
                            updateWhishlistStatus(index, 1, 1);
                        }

                        toast.success('Item added to cart successfully.', {
                            autoClose: 3000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }

                } else if (response.status === 400 && response.message === "Token is expired!!") {
                    navigate("/login");
                    window.location.reload()
                }
            }).catch((error) => {

            })

    }

    return (
        <>
            {title != "" ?
                <>
                    <section className="top-sell-product-section section-space bg-gredient-top">
                        <div className="container">
                            <div className="section-title text-center">
                                <h2 className="title">{title}</h2>
                                {/* <p>{contact_us_details != null ? contact_us_details.field : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis'} </p> */}
                            </div>
                            <div className="prod-slide-front prod-mobi-slider mb-n6 mb-sm-n6 g-3 g-sm-6">
                                <Swiper
                                    modules={[Virtual, Navigation]}
                                    rewind={true}
                                    navigation={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                        },
                                        992: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {items?.map((item, idx) => (
                                        <SwiperSlide key={item?.id}>
                                            <div className="front-prod-items mb-4 mb-sm-9" >
                                                <div className="product-item">
                                                    <div className="product-thumb">
                                                        <Link className="d-block" onClick={scrollToTop} to={`/product/${item?.slug}`}>
                                                            <img src={item?.file_url[0]} alt="Image" />
                                                        </Link>
                                                        <div className="product-action">
                                                            <button type="button" className="product-action-btn action-btn-quick-view" onClick={() => { handleShow(item); }}>
                                                                <i className="fa fa-expand"></i>
                                                            </button>
                                                            {
                                                                item?.available_unit <= 0 ?
                                                                    <button className="product-action-btn action-btn-cart pe-none"  >
                                                                        Out of stock
                                                                    </button>
                                                                    :
                                                                    <button type="button" className="product-action-btn action-btn-cart"
                                                                        onClick={() => {
                                                                            // Set cart popup to false when addToCartText is 'View Cart' 
                                                                            if (item?.cart_whishlist['cart_status'] == 1) {
                                                                                setCartpopup(false);
                                                                                navigate('/cart')
                                                                                scrollToTop()
                                                                                return
                                                                            }
                                                                            addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 1)
                                                                        }}
                                                                    >
                                                                        {item?.cart_whishlist['cart_status'] == 1 ? "View Cart" : 'Add to Cart'}
                                                                    </button>
                                                            }

                                                            <button type="button" onClick={() => {
                                                                if (item?.cart_whishlist['wishlist_status'] == 1) {
                                                                    setCartpopup(false);
                                                                    scrollToTop()
                                                                    navigate('/wishlist')
                                                                    return
                                                                }
                                                                addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 2);
                                                            }} className="product-action-btn action-btn-wishlist">
                                                                {
                                                                    item?.cart_whishlist['wishlist_status'] == 1 ? <i className="fa fa-heart"></i> :
                                                                        <i className="fa fa-heart-o"></i>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="product-info">
                                                        <div className="product-rating">

                                                            <div className="rating">
                                                                {/* Dynamically render star rating based on avg_star */}
                                                                {[...Array(Math.floor(item?.avg_star))]?.map((_, index) => (
                                                                    <i key={index} className="fa fa-star"></i>
                                                                ))}
                                                                {/* Render a half star if avg_star is between integer and integer+0.5 */}
                                                                {item?.avg_star % 1 >= 0.25 && item?.avg_star % 1 <= 0.75 && <i className="fa fa-star-half-o"></i>}
                                                                {/* Render empty stars for the remaining */}
                                                                {[...Array(5 - Math.ceil(item?.avg_star))]?.map((_, index) => (
                                                                    <i key={index + Math.ceil(item?.avg_star)} className="fa fa-star-o"></i>
                                                                ))}
                                                            </div>
                                                            <div className="reviews">{item?.review_count} reviews</div>
                                                        </div>
                                                        <h4 className="title"><Link onClick={scrollToTop} to={`/product/${item?.slug}`}>{item?.title}</Link></h4>
                                                        <div className="prices">
                                                            {
                                                                item?.offer_percentage > 0 ?
                                                                    <>
                                                                        <span className="price">₹{(item?.price - item?.discounted_price)?.toFixed(2)}</span>
                                                                        <span className="price-old">₹{item?.price?.toFixed(2)}</span>
                                                                    </>

                                                                    :
                                                                    <span className="price">₹{item?.price?.toFixed(2)}</span>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="product-action-bottom">
                                                        <button type="button" className="product-action-btn action-btn-quick-view" onClick={() => handleShow(item)}>
                                                            <i className="fa fa-expand"></i>
                                                        </button>
                                                        <button type="button" onClick={() => {
                                                            if (item?.cart_whishlist['wishlist_status'] == 1) {
                                                                setCartpopup(false);
                                                                scrollToTop()
                                                                navigate('/wishlist')
                                                                return
                                                            }
                                                            addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 2);
                                                        }} className="product-action-btn action-btn-wishlist">
                                                            {
                                                                item?.cart_whishlist['wishlist_status'] == 1 ? <i className="fa fa-heart"></i> :
                                                                    <i className="fa fa-heart-o"></i>
                                                            }
                                                        </button>
                                                        {
                                                            item?.available_unit <= 0 ?
                                                                <button className="product-action-btn action-btn-cart pe-none"  >
                                                                    Out of stock
                                                                </button>
                                                                :
                                                                <button type="button" className="product-action-btn action-btn-cart"
                                                                    onClick={() => {
                                                                        // Set cart popup to false when addToCartText is 'View Cart' 
                                                                        if (item?.cart_whishlist['cart_status'] == 1) {
                                                                            setCartpopup(false);
                                                                            navigate('/cart')
                                                                            scrollToTop()
                                                                            return
                                                                        }
                                                                        addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 1)
                                                                    }}
                                                                >
                                                                    {item?.cart_whishlist['cart_status'] == 1 ? "View Cart" : 'Add to Cart'}
                                                                </button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </section>
                </>
                :
                <>
                    {
                        !items.length ?
                            <h1 className='text-center m-10'>Product is Unavailable</h1> :
                            (<section className="product-list-main section-space bg-gredient-top">
                                <div className="container">
                                    <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
                                        {items?.map((item, idx) => (
                                            <div className="col-6 col-lg-3 mb-4 mb-sm-9" key={item?.id}>
                                                <div className="product-item">
                                                    <div className="product-thumb">
                                                        <Link className="d-block" onClick={scrollToTop} to={`/product/${item?.slug}`}>
                                                            <img src={item?.file_url[0]} alt="Image" />
                                                        </Link>
                                                        <div className="product-action">
                                                            <button type="button" className="product-action-btn action-btn-quick-view" onClick={() => handleShow(item)}>
                                                                <i className="fa fa-expand"></i>
                                                            </button>
                                                            {
                                                                item?.available_unit < 0 ?
                                                                    <button className="product-action-btn action-btn-cart pe-none"  >
                                                                        Out of stock
                                                                    </button>
                                                                    :
                                                                    <button type="button" className="product-action-btn action-btn-cart"
                                                                        onClick={() => {
                                                                            // Set cart popup to false when addToCartText is 'View Cart' 
                                                                            if (item?.cart_whishlist['cart_status'] == 1) {
                                                                                setCartpopup(false);
                                                                                navigate('/cart')
                                                                                scrollToTop()
                                                                                return
                                                                            }
                                                                            addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 1)
                                                                        }}
                                                                    >
                                                                        {item?.cart_whishlist['cart_status'] == 1 ? "View Cart" : 'Add to Cart'}
                                                                    </button>
                                                            }
                                                            <button type="button" onClick={() => {
                                                                if (item?.cart_whishlist['wishlist_status'] == 1) {
                                                                    setCartpopup(false);
                                                                    navigate('/wishlist')
                                                                    scrollToTop()
                                                                    return
                                                                }
                                                                addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 2);
                                                            }} className="product-action-btn action-btn-wishlist">
                                                                {
                                                                    item?.cart_whishlist['wishlist_status'] == 1 ? <i className="fa fa-heart"></i> :
                                                                        <i className="fa fa-heart-o"></i>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="product-info">
                                                        <div className="product-rating">
                                                            <div className="rating">
                                                                {/* Dynamically render star rating based on avg_star */}
                                                                {[...Array(Math.floor(item?.avg_star))]?.map((_, index) => (
                                                                    <i key={index} className="fa fa-star"></i>
                                                                ))}
                                                                {/* Render a half star if avg_star is between integer and integer+0.5 */}
                                                                {item?.avg_star % 1 >= 0.25 && item?.avg_star % 1 <= 0.75 && <i className="fa fa-star-half-o"></i>}
                                                                {/* Render empty stars for the remaining */}
                                                                {[...Array(5 - Math.ceil(item?.avg_star))]?.map((_, index) => (
                                                                    <i key={index + Math.ceil(item?.avg_star)} className="fa fa-star-o"></i>
                                                                ))}
                                                            </div>
                                                            <div className="reviews">{item?.review_count} reviews</div>
                                                        </div>
                                                        <h4 className="title"><Link onClick={scrollToTop} to={`/product/${item?.slug}`}>{item?.title}</Link></h4>
                                                        <div className="prices">
                                                            {
                                                                item?.offer_percentage > 0 ?
                                                                    <>
                                                                        <span className="price">₹{(item?.price - item?.discounted_price)?.toFixed(2)}</span>
                                                                        <span className="price-old">₹{item?.price?.toFixed(2)}</span>
                                                                    </>

                                                                    :
                                                                    <span className="price">₹{item?.price?.toFixed(2)}</span>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="product-action-bottom">
                                                        <button type="button" className="product-action-btn action-btn-quick-view" onClick={() => handleShow(item)}>
                                                            <i className="fa fa-expand"></i>
                                                        </button>
                                                        <button type="button" onClick={() => {
                                                            if (item?.cart_whishlist['wishlist_status'] == 1) {
                                                                setCartpopup(false);
                                                                scrollToTop()
                                                                navigate('/wishlist')
                                                                return
                                                            }
                                                            addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 2);
                                                        }} className="product-action-btn action-btn-wishlist">
                                                            {
                                                                item?.cart_whishlist['wishlist_status'] == 1 ? <i className="fa fa-heart"></i> :
                                                                    <i className="fa fa-heart-o"></i>
                                                            }
                                                        </button>
                                                        {
                                                            item?.available_unit <= 0 ?
                                                                <button className="product-action-btn action-btn-cart pe-none"  >
                                                                    Out of stock
                                                                </button>
                                                                :
                                                                <button type="button" className="product-action-btn action-btn-cart"
                                                                    onClick={() => {
                                                                        // Set cart popup to false when addToCartText is 'View Cart' 
                                                                        if (item?.cart_whishlist['cart_status'] == 1) {
                                                                            setCartpopup(false);
                                                                            navigate('/cart')
                                                                            scrollToTop()
                                                                            return
                                                                        }
                                                                        addToCart(item?.id, item?.price - item?.discounted_price, 1, item?.id, 1)
                                                                    }}
                                                                >
                                                                    {item?.cart_whishlist['cart_status'] == 1 ? "View Cart" : 'Add to Cart'}
                                                                </button>
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>)
                    }
                </>
            }
            <Modal className="product-cart-view-modal" show={show} onHide={handleClose} size="md" centered>
                {selectedProduct && (
                    <div className="modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="product-quick-view-content">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal">
                                        <span className="fa fa-close" onClick={handleClose}></span>
                                    </button>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Swiper
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    autoplay={{
                                                        delay: 3500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        type: 'fraction',
                                                    }}
                                                    navigation={true}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                >
                                                    {
                                                        selectedProduct?.file_url?.map((img, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="prod-imgbox">
                                                                    <img src={selectedProduct?.file_url[index]} alt="" />
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                    }
                                                </Swiper>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className={`${selectedProduct.available_unit <= 0 || selectedProduct?.cart_whishlist?.cart_status == 1 ? "product-details-content homeproduct outofstock " : "product-details-content homeproduct "}`}>
                                                    <h5 className="product-details-collection">New collection</h5>
                                                    <h3 className="product-details-title">{selectedProduct.title}</h3>
                                                    <p className="mb-6">{selectedProduct.short_description}</p>
                                                    <div className="product-details-review mb-5">
                                                        <div className="product-review-icon">
                                                            {/* Dynamically render star rating based on avg_star */}
                                                            {[...Array(Math.floor(selectedProduct.avg_star))]?.map((_, index) => (
                                                                <i key={index} className="fa fa-star"></i>
                                                            ))}
                                                            {/* Render a half star if avg_star is between integer and integer+0.5 */}
                                                            {selectedProduct.avg_star % 1 >= 0.25 && selectedProduct.avg_star % 1 <= 0.75 && <i className="fa fa-star-half-o"></i>}
                                                            {/* Render empty stars for the remaining */}
                                                            {[...Array(5 - Math.ceil(selectedProduct.avg_star))]?.map((_, index) => (
                                                                <i key={index + Math.ceil(selectedProduct.avg_star)} className="fa fa-star-o"></i>
                                                            ))}
                                                        </div>
                                                        <div className="product-review-show">{selectedProduct.review_count} reviews</div>
                                                    </div>
                                                    <div className="product-details-qty-list">
                                                        <div className="qty-list-check">
                                                            <label className="form-check-label" htmlFor="qtyList1">Product BV: <b>{selectedProduct.product_bv}</b></label>
                                                        </div>
                                                        <div className="qty-list-check">
                                                            <label className="form-check-label" htmlFor="qtyList2">HSN NO: <b>{selectedProduct.hsn_no}</b></label>
                                                        </div>
                                                        <div className="qty-list-check d-flex v-center">
                                                            <label className="form-check-label" htmlFor="qtyList1">Share:</label>
                                                            <div className="hero-slide-social-media static d-flex">
                                                                <Tooltip placement="bottom" title={"Twitter"} >
                                                                    <a href={`https://twitter.com/intent/tweet?url=${selectedProduct.shareable_url}`} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-twitter"></i>
                                                                    </a>
                                                                </Tooltip>

                                                                <Tooltip placement="bottom" title={"Facebook"}>
                                                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${selectedProduct.shareable_url}`} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-facebook"></i>
                                                                    </a>
                                                                </Tooltip>

                                                                <Tooltip placement="bottom" title={"Instagram"}>
                                                                    <a href={`https://www.instagram.com/`} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-instagram"></i>
                                                                    </a>
                                                                </Tooltip>

                                                                <Tooltip placement="bottom" title={"LinkedIn"}>
                                                                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${selectedProduct.shareable_url}`} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-linkedin"></i>
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        selectedProduct.available_unit > 0 ?
                                                            selectedProduct.cart_whishlist['cart_status'] !== 1 &&
                                                            <div className="product-details-pro-qty">
                                                                <div className="pro-qty">
                                                                    <div className='dec qty-btn' onClick={() => { if (quantity > 1) setQuantity(quantity - 1) }} >-</div>
                                                                    <input type="text" title="Quantity" value={quantity} />
                                                                    <div className='inc qty-btn' onClick={() => setQuantity(quantity + 1)}>+</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <h5>Out of stock </h5>
                                                    }

                                                    <div className="product-details-action">
                                                        {
                                                            selectedProduct?.offer_percentage > 0 ?
                                                                <span className="price">₹{(selectedProduct.price - selectedProduct.discounted_price)?.toFixed(2)}</span> :
                                                                <span className="price">₹{selectedProduct.price?.toFixed(2)}</span>
                                                        }

                                                        <div className="product-details-cart-wishlist">
                                                            {selectedProduct?.available_unit > 0 &&
                                                                <button type="button" className="product-action-btn action-btn-cart btn"
                                                                    onClick={() => {
                                                                        // Set cart popup to false when addToCartText is 'View Cart' 
                                                                        if (selectedProduct.cart_whishlist['cart_status'] == 1) {
                                                                            setCartpopup(false);
                                                                            navigate('/cart')
                                                                            scrollToTop()
                                                                            return
                                                                        }
                                                                        addToCart(selectedProduct?.id, selectedProduct.price - selectedProduct.discounted_price, quantity, selectedProduct.id, 1)
                                                                    }}
                                                                >
                                                                    {selectedProduct.cart_whishlist['cart_status'] == 1 ? "View Cart" : 'Add to Cart'}
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </Modal>
        </>
    )
}

export default Product;