import React from 'react'
import image1 from '../../images/pro_img1.jpg'
import image2 from '../../images/pro_img2.jpg'
import image3 from '../../images/pro_img3.jpg'
import { Link } from 'react-router-dom'

const ProductCompare = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <nav aria-label="breadcrumb" className="breadcrumb-style1 pt-5 pb-5">
                        <div className="container">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Product Compare</li>
                            </ol>
                        </div>
                    </nav>
                    <section className="wishlist-section section-space">
                        <div className="container">
                            <div className="shopping-compare-form table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th className="fz-13">Product Info</th>
                                            <td>
                                                <div className="product-remove">
                                                    <Link onClick={scrollToTop} to="#/"><i className="fa fa-times"></i>Remove</Link>
                                                </div>
                                                <div className="product-thumb">
                                                    <Link onClick={scrollToTop} to="/product_detail">
                                                        <img src={image1} width="80" alt="" />
                                                    </Link>
                                                </div>
                                                <div className="product-name">
                                                    <h4 className="title"><Link onClick={scrollToTop} to="/product_detail">Hand Wash 250ml</Link></h4>
                                                </div>
                                                <Link onClick={scrollToTop} to="/cart" className="btn-cart">Add to cart</Link>
                                            </td>
                                            <td>
                                                <div className="product-remove">
                                                    <Link onClick={scrollToTop} to="#/"><i className="fa fa-times"></i>Remove</Link>
                                                </div>
                                                <div className="product-thumb">
                                                    <Link onClick={scrollToTop} to="/product_detail">
                                                        <img src={image2} width="80" alt="" />
                                                    </Link>
                                                </div>
                                                <div className="product-name">
                                                    <h4 className="title"><Link onClick={scrollToTop} to="/product_detail">Clove & Mint Toothpaste 100gm</Link></h4>
                                                </div>
                                                <Link onClick={scrollToTop} to="/cart" className="btn-cart">Add to cart</Link>
                                            </td>
                                            <td>
                                                <div className="product-remove">
                                                    <Link onClick={scrollToTop} to="#/"><i className="fa fa-times"></i>Remove</Link>
                                                </div>
                                                <div className="product-thumb">
                                                    <Link onClick={scrollToTop} to="/product_detail">
                                                        <img src={image3} width="80" alt="" />
                                                    </Link>
                                                </div>
                                                <div className="product-name">
                                                    <h4 className="title"><Link onClick={scrollToTop} to="/product_detail">Watermelon Facewash 100gm</Link></h4>
                                                </div>
                                                <Link onClick={scrollToTop} to="/cart" className="btn-cart">Add to cart</Link>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Price</th>
                                            <td className="price"><strong>₹69.99</strong></td>
                                            <td className="price"><strong>₹69.99</strong></td>
                                            <td className="price"><strong>₹69.99</strong></td>
                                        </tr>

                                        <tr>
                                            <th>Sku</th>
                                            <td className="product-sku">REF. LA-791</td>
                                            <td className="product-sku">REF. LA-779</td>
                                            <td className="product-sku">REF. LA-788</td>
                                        </tr>

                                        <tr>
                                            <th>Description</th>
                                            <td className="product-desc">Class aptent taciti sociosqu ad litora torquent per conubia nostra,…</td>
                                            <td className="product-desc">Class aptent taciti sociosqu ad litora torquent per conubia nostra,…</td>
                                            <td className="product-desc">Class aptent taciti sociosqu ad litora torquent per conubia nostra,…</td>
                                        </tr>

                                        <tr>
                                            <th>Availability</th>
                                            <td><span className="product-stock">In stock</span></td>
                                            <td><span className="product-stock">In stock</span></td>
                                            <td><span className="product-stock">In stock</span></td>
                                        </tr>

                                        <tr>
                                            <th>Weight</th>
                                            <td className="product-weight">N/A</td>
                                            <td className="product-weight">N/A</td>
                                            <td className="product-weight">N/A</td>
                                        </tr>

                                        <tr>
                                            <th>Dimensions</th>
                                            <td className="product-dimensions">N/A</td>
                                            <td className="product-dimensions">N/A</td>
                                            <td className="product-dimensions">N/A</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    )
}

export default ProductCompare
