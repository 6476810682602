import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../sections/ScrollToTop';
import { useForm } from 'react-hook-form';
import AxiosInstance from '../../Api/AxiosInstance';
const ForgotPassword = () => {

    const [message, setMessage] = useState(false)

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const handleForgetPassword = async (data) => {
        setMessage(false)
        try {
            const response = await AxiosInstance.post("forgot-password", data)
            if (response.status == 200 && response.statusText == "OK") {
                setMessage(true)
                setValue("email", "")
            }

        } catch (error) {

        }
    }

    const EmailChange = () => {
        setMessage(false)
    }

    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">

                    <section className="page-header-area pt-5 pb-5" data-bg-color="#FFF3DA" style={{ backgroundColor: "#FFF3DA" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="page-header-st3-content text-center text-md-start">
                                        <ol className="breadcrumb justify-content-center justify-content-md-start mb-0">
                                            <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                                            <li className="breadcrumb-item active text-dark" aria-current="page">Account</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-space">
                        <div className="container">
                            <div className="row mb-n8">
                                <div className="col-lg-6 mb-8 ml-auto mr-auto">
                                    <div className="my-account-item-wrap">
                                        <h3 className="title">Reset password</h3>
                                        <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                                        <div className="my-account-form">
                                            <form onSubmit={handleSubmit(handleForgetPassword)}>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="login_username">Email ID<sup>*</sup></label>
                                                    <input type="text" placeholder='Email ID' id="login_username"   {...register('email', {
                                                        required: "Email ID is required",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                            message: "Invalid email ID",
                                                        } ,
                                                        onChange : EmailChange
                                                    })}

                                                    />
                                                    {errors.email && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.email.message}</p>}
                                                </div>

                                                <div className="form-group text-center">
                                                    <button className="btn w-100" type='submit' >Reset password</button>
                                                </div>

                                                {message && <p className='mt-5 alert alert-success' >The reset link has been sent to your email address.</p>}
                                                <div className="form-group mt-3">
                                                    <Link className="lost-password" onClick={scrollToTop} to="/login"><u>You have an account ? Login to your account</u></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    )
}

export default ForgotPassword