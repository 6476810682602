import React, { useEffect, useState } from "react";
import product_banner from "../../images/product-banner.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Product from "../sections/Product";
import AxiosInstance from "../../Api/AxiosInstance";
import { scrollToTop } from "../sections/ScrollToTop";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Tooltip } from "antd";
import Loader from "./Loader";

const ProductDetail = () => {
  const id = useParams();
  const [product, setProduct] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [cratpopup, setCartpopup] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(5); // Initial rating value
  const [variant, setVariant] = useState("");
  const [variantvalue, setVariantvalue] = useState("");
  const [selectedvariant , setSelectedvariant] = useState('')
  const handleRatingChange = (event) => {
    setRating(parseInt(event.target.value)); // Update the rating state when the select input changes
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const fetchProduct = async () => {
    try {
      const response = await AxiosInstance.post("products", { slug: id });
      setProduct(response.data.data);
      setImage(response.data.data[0].file_url);
      setVariantvalue(response.data.data[0].variant_name);
      setVariant(response.data.data[0].slug);
      setLoading(false);
    } catch (error) {}
  };

  const relatedProduct = async () => {
    try {
      const response = await AxiosInstance.post("products");
      setRelatedProducts(response.data.data);
    } catch (error) {}
  };

  const submit = async (data, e) => {
    try {
      const response = await AxiosInstance.post("add-review", {
        full_name: data.full_name,
        email: data.email,
        star: data.star,
        feedback: data.feedback,
        product_id: product[0].id,
      });
      if (response.data.success) {
        toast.success("Thank you for Review!", {
          position: "top-right",
          autoClose: 3000,
        });
        e.target.reset();
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchProduct();
    relatedProduct();
  }, [id]);
  const updateWhishlistStatus = (index, newStatus, type_id) => {
    setProduct((prevProductData) => {
      const statusKey = type_id === 1 ? "cart_status" : "wishlist_status";
      const updatedProductData = [...prevProductData];
      updatedProductData[index] = {
        ...updatedProductData[index],
        cart_whishlist: {
          ...updatedProductData[index].cart_whishlist,
          [statusKey]: newStatus,
        },
      };
      type_id === 1
        ? document.dispatchEvent(new Event("itemAddedToCart"))
        : document.dispatchEvent(new Event("itemAddedToWishlist"));
      return updatedProductData;
    });
  };
  function addToCart(id, price, qty, index, type_id) {
    const obj = {
      product_id: id,
      price: price,
      quantity: qty,
      sub_total: price * qty,
      type_id: type_id,
    };
    AxiosInstance.post("add-to-cart", obj)
      .then((response) => {
        if (response.data.success) {
          const index = product.findIndex((item) => item?.id === id);

          if (type_id == 2) {
            toast.success("Item added to wishlist successfully.", {
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
            });
            if (index !== -1) {
              updateWhishlistStatus(index, 1, 2);
            }
          } else {
            if (index !== -1) {
              updateWhishlistStatus(index, 1, 1);
            }
            toast.success("Item added to cart successfully.", {
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        } else if (
          response.status === 400 &&
          response.message === "Token is expired!!"
        ) {
          navigate("/login");
          window.location.reload();
        }
      })
      .catch((error) => {});
  }

  const handleVariantChange = async (value) => {
    setLoading(true)
    try {
      const response = await AxiosInstance.post("products", { slug: value });
      setProduct(response.data.data);
      setImage(response.data.data[0].file_url);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="wrapper">
          <div className="blank_header trans"></div>
          <main className="main-content">
            <section
              className="page-header-area pt-5 pb-5"
              style={{ backgroundColor: "#FFF3DA" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <div className="page-header-st3-content text-center text-md-start">
                      <ol className="breadcrumb justify-content-md-start mb-0">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-dark"
                            onClick={scrollToTop}
                            to="/"
                          >
                            Home
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link
                            className="text-dark"
                            onClick={scrollToTop}
                            to="/shop"
                          >
                            Product
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link className="text-dark">{id.slug}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active text-dark"
                          aria-current="page"
                        >
                          {product[0]?.name}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pro-main-section section-space">
              <div className="pro-inn-block">
                <div className="container">
                  <div className="row product-details section-space pt-0">
                    {product?.map((item, idx) => (
                      <>
                        <div
                          className="col-lg-6 position-relative imgbox-pro-mobi"
                          key={idx}
                        >
                          <div className="prod-slide-main prod-swiper-container">
                            <div className="swiper-wrapper">
                              <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                  delay: 2500,
                                  disableOnInteraction: false,
                                }}
                                pagination={{
                                  type: "fraction",
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                              >
                                {image?.map((img, index) => (
                                  <SwiperSlide key={index}>
                                    <div className="prod-imgbox">
                                      <img src={image[index]} alt="" />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 pro-text-right-block">
                          <div
                            className={`${
                              item?.available_unit < 0
                                ? "product-details-content "
                                : item?.cart_whishlist?.cart_status == 1
                                ? "product-details-content outofstock dtl"
                                : "product-details-content"
                            }`}
                          >
                            <div className="white-text-box">
                              <h5 className="product-details-collection">
                                {item?.short_description}
                              </h5>
                              
                              <h3 className="product-details-title">
                                {item?.title} 
                              </h3>

                              <div className="product-details-review">
                                <div className="product-review-icon">
                                  {[...Array(Math.floor(item?.avg_star))]?.map(
                                    (_, index) => (
                                      <i key={index} className="fa fa-star"></i>
                                    )
                                  )}
                                  {/* Render a half star if avg_star is between integer and integer+0.5 */}
                                  {item?.avg_star % 1 >= 0.25 &&
                                    item?.avg_star % 1 <= 0.75 && (
                                      <i className="fa fa-star-half-o"></i>
                                    )}
                                  {/* Render empty stars for the remaining */}
                                  {[
                                    ...Array(5 - Math.ceil(item?.avg_star)),
                                  ]?.map((_, index) => (
                                    <i
                                      key={index + Math.ceil(item?.avg_star)}
                                      className="fa fa-star-o"
                                    ></i>
                                  ))}
                                </div>
                                <p className="product-review-show">
                                  {item?.review_count} reviews
                                </p>
                              </div>
                            </div>

                            <div className="product-details-qty-list">
                              <div className="qty-list-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="qtyList1"
                                >
                                  Product BV: <b>{item?.product_bv}</b>
                                </label>
                              </div>
                              <div className="qty-list-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="qtyList2"
                                >
                                  HSN NO: <b>{item?.hsn_no}</b>
                                </label>
                              </div>
                              <div className="qty-list-check d-flex v-center">
                                <label
                                  className="form-check-label"
                                  htmlFor="qtyList1"
                                >
                                  Share:
                                </label>
                                <div className="hero-slide-social-media static d-flex">
                                  <Tooltip placement="bottom" title={"Twitter"}>
                                    <a
                                      href={`https://twitter.com/intent/tweet?url=${item?.shareable_url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="fa fa-twitter"></i>
                                    </a>
                                  </Tooltip>

                                  <Tooltip
                                    placement="bottom"
                                    title={"Facebook"}
                                  >
                                    <a
                                      href={`https://www.facebook.com/sharer/sharer.php?u=${item?.shareable_url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="fa fa-facebook"></i>
                                    </a>
                                  </Tooltip>

                                  <Tooltip
                                    placement="bottom"
                                    title={"Instagram"}
                                  >
                                    <a
                                      href={`https://www.instagram.com/`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="fa fa-instagram"></i>
                                    </a>
                                  </Tooltip>

                                  <Tooltip
                                    placement="bottom"
                                    title={"LinkedIn"}
                                  >
                                    <a
                                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${item?.shareable_url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="fa fa-linkedin"></i>
                                    </a>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>

                            {item?.available_unit > 0 ? (
                              <>
                                {item?.product_varations?.length > 0 && (
                                  <div className="variant product-details-pro-qty">
                                    <div className="product-reviews-form d-flex v-center w-100">
                                      <label
                                        className="form-check-label"
                                        style={{ minWidth: "120px" }}
                                      >
                                        Variant
                                      </label>
                                      <div className="selectbox select-ratings w-100 ml-2">
                                        {/* <select
                                          value={variant}
                                          onChange={(e) => {
                                            setLoading(true);
                                            handleVariantChange(e.target.value);
                                          }}
                                        >
                                          <option value={variant}>
                                            {variantvalue}
                                          </option>
                                          {item?.product_varations?.filter((data) => data.variant_name !== variantvalue).map(
                                            (category) => (
                                              <option
                                                value={category?.slug}
                                                key={category?.id}
                                              >
                                                {category?.variant_name}
                                              </option>
                                            )
                                          )}
                                        </select> */}

                                        <select
                                        value={selectedvariant}
                                          onChange={(e) =>
                                            {handleVariantChange(e.target.value)
                                            setSelectedvariant(e.target.value)}
                                          }
                                          disabled={loading} // Optionally disable the select element while loading
                                        >
                                            <option value={variant}>
                                              {variantvalue}
                                            </option>
                                          {item?.product_varations
                                            ?.filter(
                                              (data) =>
                                                data.variant_name !==
                                                variantvalue
                                            ) // Exclude the currently selected variant
                                            .map((category) => (
                                              <option
                                                value={category?.slug}
                                                key={category?.id}
                                                selected={selectedvariant}
                                              >
                                                {category?.variant_name}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="check-pincode-block d-flex">
                                  <p>
                                    The product will be delivered within 5 to 7
                                    working days.
                                  </p>
                                </div>

                                {item?.cart_whishlist["cart_status"] !== 1 && (
                                  <div className="product-details-pro-qty">
                                    <div className="pro-qty">
                                      <div
                                        className="dec qty-btn"
                                        onClick={() => {
                                          if (quantity > 1)
                                            setQuantity(quantity - 1);
                                        }}
                                      >
                                        -
                                      </div>
                                      <input
                                        type="text"
                                        title="Quantity"
                                        value={quantity}
                                      />
                                      <div
                                        className="inc qty-btn"
                                        onClick={() =>
                                          setQuantity(quantity + 1)
                                        }
                                      >
                                        +
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="product-details-pro-qty">
                                <div className="pro-qty">
                                  <h5>Out of stock</h5>
                                </div>
                              </div>
                            )}

                            <div className="product-details-action">
                              {item?.offer_percentage > 0 ? (
                                <h4 className="price">
                                  ₹
                                  {(
                                    item?.price - item?.discounted_price
                                  )?.toFixed(2)}
                                </h4>
                              ) : (
                                <h4 className="price">
                                  ₹{item?.price?.toFixed(2)}
                                </h4>
                              )}

                              <div className="product-details-cart-wishlist">
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      item?.cart_whishlist["wishlist_status"] ==
                                      1
                                    ) {
                                      setCartpopup(false);
                                      navigate("/wishlist");
                                      return;
                                    }
                                    addToCart(
                                      item?.id,
                                      item?.price - item?.discounted_price,
                                      1,
                                      item?.id,
                                      2
                                    );
                                  }}
                                  className="btn-wishlist"
                                >
                                  {item?.cart_whishlist["wishlist_status"] ==
                                  1 ? (
                                    <i className="fa fa-heart"></i>
                                  ) : (
                                    <i className="fa fa-heart-o"></i>
                                  )}
                                </button>
                                {item?.available_unit > 0 && (
                                  <button
                                    type="button"
                                    className="product-action-btn action-btn-cart btn"
                                    onClick={() => {
                                      // Set cart popup to false when addToCartText is 'View Cart'
                                      if (
                                        item?.cart_whishlist["cart_status"] == 1
                                      ) {
                                        setCartpopup(false);
                                        navigate("/cart");
                                        return;
                                      }
                                      addToCart(
                                        item?.id,
                                        item?.price - item?.discounted_price,
                                        quantity,
                                        item?.id,
                                        1
                                      );
                                    }}
                                  >
                                    {item?.cart_whishlist["cart_status"] == 1
                                      ? "View Cart"
                                      : "Add to Cart"}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div className="product-other-info bg-gray section-space">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 prod-d-left">
                      <div
                        className="nav product-details-nav"
                        id="product-details-nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="specification-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#specification"
                          type="button"
                          role="tab"
                          aria-controls="specification"
                          aria-selected="false"
                        >
                          Specification
                        </button>
                        {product[0]?.product_reviews.length > 0 && (
                          <button
                            className="nav-link"
                            id="review-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#review"
                            type="button"
                            role="tab"
                            aria-controls="review"
                            aria-selected="true"
                          >
                            Reviews
                          </button>
                        )}
                      </div>

                      {product?.map((item) => (
                        <div
                          className="tab-content"
                          id="product-details-nav-tabContent"
                          key={item?.id}
                        >
                          <div
                            className="tab-pane show active"
                            id="specification"
                            role="tabpanel"
                            aria-labelledby="specification-tab"
                          >
                            <ul className="product-details-info-wrap">
                              {item?.weight && (
                                <li>
                                  <span>Weight :</span>
                                  <p>{item?.weight}</p>
                                </li>
                              )}

                              {item?.dimensions && (
                                <li>
                                  <span>Dimensions :</span>
                                  <p>{item?.dimensions}</p>
                                </li>
                              )}

                              {item?.materials && (
                                <li>
                                  <span>Materials :</span>
                                  <p>{item?.materials}</p>
                                </li>
                              )}
                              {item?.other_info && (
                                <li>
                                  <span>Other Info :</span>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item?.other_info,
                                    }}
                                  />
                                </li>
                              )}
                            </ul>
                            {item?.long_description && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.long_description,
                                }}
                              />
                            )}
                          </div>

                          <div
                            className="tab-pane fade"
                            id="review"
                            role="tabpanel"
                            aria-labelledby="review-tab"
                          >
                            {item?.product_reviews?.map((item) => (
                              <div
                                className="product-review-item"
                                key={item?.id}
                              >
                                <div className="product-review-top">
                                  <div className="product-review-content">
                                    <span className="product-review-name">
                                      {item?.full_name}
                                    </span>
                                    <div className="product-review-icon">
                                      {
                                        // Generating stars based on 'star' value
                                        Array.from({ length: 5 }, (_, index) =>
                                          index < Math.floor(item?.star) ? (
                                            <i
                                              key={index}
                                              className="fa fa-star"
                                            ></i>
                                          ) : (
                                            <i
                                              key={index}
                                              className="fa fa-star-o"
                                            ></i>
                                          )
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                                <p className="desc">{item?.feedback}</p>
                                {/* <button type="button" className="review-reply"><i className="fa fa fa-undo"></i></button> */}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="col-lg-5 prod-d-right">
                      <div className="product-reviews-form-wrap">
                        <h4 className="product-form-title">
                          <strong>Leave a reply</strong>
                        </h4>
                        <div className="product-reviews-form">
                          <form onSubmit={handleSubmit(submit)}>
                            <div className="form-input-item">
                              <textarea
                                className="form-control"
                                placeholder="Enter you feedback"
                                {...register("feedback", {
                                  required: "Feedback is required",
                                })}
                              ></textarea>
                              <p
                                className="mt-2"
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.feedback?.message}
                              </p>
                            </div>
                            <div className="form-input-item">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Full Name"
                                {...register("full_name", {
                                  required: "Full Name is required",
                                })}
                              />
                              <p
                                className="mt-2"
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.full_name?.message}
                              </p>
                            </div>
                            <div className="form-input-item">
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Email Address"
                                {...register("email", {
                                  required: "Email Address is required ",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Enter a valid Email Address",
                                  },
                                })}
                              />
                              <p
                                className="mt-2"
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {errors.email?.message}
                              </p>
                            </div>
                            <div className="form-input-item">
                              <div className="form-ratings-item">
                                <div className="selectbox select-ratings">
                                  <select
                                    {...register("star")}
                                    value={rating}
                                    onChange={handleRatingChange}
                                  >
                                    <option value="1">01</option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                    <option value="5">05</option>
                                  </select>
                                </div>
                                <span className="title">
                                  Provide Your Ratings
                                </span>
                                <div className="product-ratingsform-form-wrap">
                                  <div className="product-ratingsform-form-icon">
                                    {Array.from({ length: 5 }, (_, index) => (
                                      <i
                                        key={index}
                                        className={`fa fa-star${
                                          index < rating ? "" : "-o"
                                        }`}
                                      ></i>
                                    ))}
                                  </div>
                                  {/* This div renders the filled stars based on the selected rating */}
                                  <div
                                    id="product-review-form-rating"
                                    className="product-ratingsform-form-icon-fill"
                                    style={{ width: `${rating * 20}%` }}
                                  >
                                    {Array.from({ length: 5 }, (_, index) => (
                                      <i key={index} className="fa fa-star"></i>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-input-item mb-0">
                              <button type="submit" className="btn">
                                SUBMIT
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container">
              <Link
                onClick={scrollToTop}
                to="/shop"
                className="product-banner-item"
              >
                <img src={product_banner} alt="" />
              </Link>
            </div>

            <section className="related-product-section section-space">
              <div className="container">
                <Product
                  title="related Product"
                  product_data={relatedProducts}
                  field=""
                />
              </div>
            </section>
          </main>
        </div>
      )}
    </>
  );
};
export default ProductDetail;
