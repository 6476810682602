import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AxiosInstance from "../../Api/AxiosInstance";
import { scrollToTop } from '../sections/ScrollToTop';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Register = () => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [City, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [sponsererr, setSponsererr] = useState(false)
  const [relation, setRelation] = useState([])
  const [id_proof, setId_proof] = useState([])
  const [sponsorName, setSponserName] = useState('')
  const [emailerr, setEmailerr] = useState('')
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setconfirmpasswordType] = useState("password");
  const [maxDate, setMaxDate] = useState('');
  const [panfile, setPanfile] = useState([]);
  const [idfile, setIdfile] = useState([]);
  const [checkfile , setCheckfile] = useState([])
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [panerror, setPanerror] = useState('')
  const [idprooferror, setIdprooferror] = useState('')
  const [cancelcheckerror, setCancelcheckerror] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setMaxDate(formattedDate);
  }, []);


  const handlePanChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ['image/jpeg','image/jpg','image/bmp', 'image/png', 'application/pdf'];

    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
    if (invalidFiles.length > 0) {
      setPanerror('Only image and PDF files are allowed.');
    } else {
      setPanerror('');
      setPanfile((prevFiles) => [...prevFiles, ...files]);
    }

  };

  const handleIdProofChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ['image/jpeg','image/jpg','image/bmp', 'image/png', 'application/pdf'];

    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
    if (invalidFiles.length > 0) {
      setIdprooferror('Only image and PDF files are allowed.');
    } else {
      setIdprooferror('');
      setIdfile((prevFiles) => [...prevFiles, ...files]);
    }
  };


  const handleCANCELCHECKChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ['image/jpeg','image/jpg','image/bmp', 'image/png', 'application/pdf'];

    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
    if (invalidFiles.length > 0) {
      setCancelcheckerror('Only image and PDF files are allowed.');
    } else {
      setCancelcheckerror('');
      setCheckfile(files);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm();

  const togglePassword = (id) => {
    if (id == "password") {
      if (passwordType === "password") {
        setPasswordType("text");
        return true;
      }
      setPasswordType("password");
      return true;
    } else {
      if (confirmpasswordType === "password") {
        setconfirmpasswordType("text");
        return true;
      }
      setconfirmpasswordType("password");
      return true;
    }

  };


  useEffect(() => {
    const registrationDropdown = async () => {
      try {
        const response = await AxiosInstance.post('registration-option-details')
        setId_proof(response.data.data.id_proofs)
        setRelation(response.data.data.relations)
      } catch (error) {

      }
    }
    registrationDropdown()
  }, [])





  const handleStateChange = (e) => {
    setSelectedOption(e.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };


  useEffect(() => {

    AxiosInstance.post('cities', { state_id: selectedOption })
      .then(response => {
        const cities = response?.data?.data?.cities;
        if (Array.isArray(cities)) {
          const options1 = cities?.map(option => ({
            value: option.id,
            label: option.name,
          }));
          setCity(options1);
        } else {
          setCity([]); // Set city options to an empty array or handle it as per your requirement
        }
      })
      .catch(error => {
        setCity([]); // Optionally, set city options to an empty array in case of error
      });

  }, [selectedOption]);

  useEffect(() => {

    const fetchState = async () => {
      try {
        const response = await AxiosInstance.post('states')
        setOptions(response.data.data.states)
      } catch (error) {

      }
    }
    fetchState()
  }, []);


  const handleSponsorIdChange = async (e) => {
    try {
      setSponserName('')
      const response = await AxiosInstance.post(`check-sponsor/${e.target.value}`)
      if (e.target.value == '' || e.target.value == "") {
        setSponsererr(false)
        return
      }

      if (response.data.message == "Sponsor not found.") {
        setSponsererr(true)
        return
      }

      if (response.data.success) {
        setSponsererr(false)
        setSponserName(response.data.data.first_name)
        setValue('sponsor_name', response.data.data.first_name, { shouldValidate: true });
      }
    } catch (error) {

    }
  }


  const handleRegister = (data) => {
    setEmailerr("")
    if (!sponsererr) {
      setLoading(true)
      data = { ...data, pan_card: panfile, id_proof: idfile, cancel_cheque: checkfile ,gst_no: data.gst_no || '' }
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (Array.isArray(data[key])) {
            data[key].forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        }
      }

      fetch(process.env.REACT_APP_API_URL + 'user-register', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setLoading(false)
            setRegisterSuccess(true)
            setTimeout(() => {
              scrollToTop()
              navigate('/login')
            }, 10000);

          } else if (data.data.message?.email[0] == "The email  has already been taken.") {
            setLoading(false)
            setEmailerr("This email has already been used.")
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const validateGstNo = value => {
    const gstNoRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{3}$/;
    return value === '' || gstNoRegex.test(value) || 'Enter a valid GST No.';
  };

  return (
    <>
      <div className="wrapper">
        <div className="blank_header trans"></div>
        <main className="main-content">
          <section className="page-header-area pt-5 pb-5" style={{ backgroundColor: "#FFF3DA" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="page-header-st3-content text-center text-md-start">
                    <ol className="breadcrumb justify-content-center justify-content-md-start mb-0">
                      <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                      <li className="breadcrumb-item active text-dark" aria-current="page">Register</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-space">
            <div className="container">
              <div className="row mb-n8">
                <h3 className="title p-align-center">Register</h3>
                <div className="col-lg-12 mb-8">
                  <div className="my-account-item-wrap">
                    <h3 className="title">Personal Information</h3>
                    <div className="my-account-form">
                      <form onSubmit={handleSubmit(handleRegister)}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="sponsor_id">Sponsor ID<sup>*</sup></label>
                              <input type="text" id="sponsor_id" placeholder='Sponsor ID' {...register('sponsor_id', {
                                required: "Sponsor ID is required",
                                onChange: handleSponsorIdChange
                              })}
                              />
                              {errors.sponsor_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.sponsor_id.message}</p>}
                              {!errors.sponsor_id && sponsererr && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>Sponser ID not found.</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="sponsor_name">Sponsor Name<sup>*</sup></label>
                              <input type="text" id="sponsor_name" value={sponsorName} placeholder='Sponsor Name'   {...register('sponsor_name', {
                                required: "Sponsor Name is required",
                              })} />
                              {errors.sponsor_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.sponsor_name.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="side_id">Side ID<sup>*</sup></label>
                              <div className='selectbox'>

                                <select name="relation" id="nominee" {...register('side_id', {
                                  required: "Side ID is required",
                                })}>
                                  <option value="">Select Side ID</option>
                                  <option value="1">Left</option>
                                  <option value="2">Right</option>
                                </select>
                              </div>
                              {errors.side_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.side_id.message}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="first_name">First Name<sup>*</sup></label>
                              <input type="text" id="first_name" placeholder='First Name' {...register('first_name', {
                                required: "First Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "First Name must contain only characters"
                                }
                              })} />
                              {errors.first_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.first_name.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="middle_name">Middle Name<sup>*</sup></label>
                              <input type="text" id="middle_name" placeholder='Middle Name' {...register('middle_name', {
                                required: "Middle Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Middle Name must contain only characters"
                                }
                              })} />
                              {errors.middle_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.middle_name.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="last_name">Last Name<sup>*</sup></label>
                              <input type="text" id="last_name" placeholder='Last Name' {...register('last_name', {
                                required: "Last Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Last Name must contain only characters"
                                }
                              })} />
                              {errors.last_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.last_name.message}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="email">Email ID<sup>*</sup></label>
                              <input type="email" id="email" placeholder='Email ID' onChange={() => setEmailerr('')} {...register('email', {
                                required: "Email ID is required",
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Enter a valid Email ID"
                                }
                              })} />
                              {errors.email && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.email.message}</p>}

                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="mobile_no">Mobile Number<sup>*</sup></label>
                              <input type="number" placeholder='Mobile Number' id="mobile_no"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                  e.target.value = e.target.value.slice(0, 10);
                                }}
                                {...register('mobile_no', {
                                  required: "Mobile Number is required",
                                  minLength: {
                                    value: 10,
                                    message: "Mobile Number must be 10 digits"
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "Mobile Number must be 10 digits"
                                  },
                                  pattern: {
                                    value: /^[1-9][0-9]{9}$/, // Pattern to ensure mobile number doesn't start with 0
                                    message: "Enter valid Mobile Number"
                                  }
                                })} />
                              {errors.mobile_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.mobile_no.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="phone_no">Phone Number<sup>*</sup></label>
                              <input type="number" id="phone_no" placeholder='Phone Number'
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                  e.target.value = e.target.value.slice(0, 10);
                                }}
                                {...register('phone_no', {
                                  required: "Phone Number is required",
                                  minLength: {
                                    value: 10,
                                    message: "Phone Number must be 10 digits"
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "Phone Number must be 10 digits"
                                  },
                                  pattern: {
                                    value: /^[1-9][0-9]{9}$/, // Pattern to ensure mobile number doesn't start with 0
                                    message: "Enter valid Phone Number"
                                  }
                                })} />
                              {errors.phone_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.phone_no.message}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="date_of_birth">Date Of Birth<sup>*</sup></label>
                              <input type="date" id="date_of_birth" max={maxDate} onKeyDown={(e) => e.preventDefault()}  {...register('date_of_birth', {
                                required: "Date Of Birth is required",
                              })} />
                              {errors.date_of_birth && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.date_of_birth.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6 pwd-eye">
                              <label htmlFor="password">Password<sup>*</sup></label>
                              <input type={passwordType} id="password" placeholder='Password' {...register('password', {
                                required: "Password is required",
                                minLength: {
                                  value: 6,
                                  message: "Password must be at least 6 characters"
                                }
                              })} />
                              <div onClick={() => togglePassword("password")}>
                                {passwordType === "password" ? (

                                  <AiOutlineEye className='pass-view' />

                                ) : (
                                  <AiOutlineEyeInvisible className='pass-view' />
                                )}
                              </div>
                              {errors.password && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.password.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6 pwd-eye">
                              <label htmlFor="confirm_password">Confirm Password<sup>*</sup></label>
                              <input type={confirmpasswordType} placeholder='Confirm Password' id="confirm_password" {...register('confirm_password', {
                                required: "Confirm Password is required",
                                validate: value => value === watch('password') || "Passwords do not match"
                              })} />
                              <div onClick={() => togglePassword("confirm_password")}>

                                {confirmpasswordType === "password" ? (
                                  <AiOutlineEye className='pass-view' />

                                ) : (
                                  <AiOutlineEyeInvisible className='pass-view' />
                                )}
                              </div>
                              {errors.confirm_password && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.confirm_password.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-6 radio-btn">
                              <label htmlFor="gender">Gender<sup>*</sup></label>
                              <div className='d-flex'>
                                <div className='radio-item d-flex'>
                                  <input type='radio' name='gender' value='2' {...register("gender", {
                                    required: "Gender is required"
                                  })}
                                  /> <label>Male</label>
                                </div>
                                <div className='radio-item d-flex'>
                                  <input type='radio' name='gender' value='1' {...register('gender')} /> <label> Female</label>
                                </div>
                              </div>
                              {errors.gender && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.gender.message}</p>}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 className="title">Address Details</h3>
                        <div className="row">

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="nominee">State<sup>*</sup></label>
                              <div className='selectbox'>
                                <select name="state_id" id="state" value={selectedOption}
                                  {...register('state_id', {
                                    required: "State is required",
                                    onChange: handleStateChange
                                  })}>
                                  <option value=''> Select State</option>
                                  {
                                    options?.map((item) => (
                                      <option value={item?.id} key={item?.id}>{item?.name}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              {errors.state_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.state_id.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="nominee">City<sup>*</sup></label>
                              <div className='selectbox'>
                                <select name="city_id" id="city" value={selectedCity}
                                  {...register('city_id', {
                                    required: "City is required",
                                    onChange: handleCityChange
                                  })}>
                                  <option value="">Select City</option>
                                  {
                                    City?.map((item) => (
                                      <option value={item?.value} key={item?.id}>{item?.label}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              {errors.city_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.city_id.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="postal_code">Postal Code<sup>*</sup></label>
                              <input type="number" id="postal_code" placeholder='Postal Code' onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g, '');
                                e.target.value = e.target.value.slice(0, 6);
                              }}
                                {...register('pincode', {
                                  required: "Postal Code is required",
                                  minLength: {
                                    value: 6,
                                    message: "Postal Code must be 6 digits"
                                  },
                                  maxLength: {
                                    value: 6,
                                    message: "Postal Code must be 6 digits"
                                  }
                                })} />
                              {errors.pincode && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.pincode.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-6">
                              <label htmlFor="address">Address<sup>*</sup></label>
                              <input placeholder='Address' type="text" id="address" {...register('address', {
                                required: "Address is required",
                              })} />
                              {errors.address && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.address.message}</p>}
                            </div>
                          </div>


                          <hr />
                          <h3 className="title">Add Nominee Information</h3>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="confirm_password" >Nominee Name<sup>*</sup></label>
                              <input type="text" placeholder='Nominee Name' {...register('nominee_name', {
                                required: "Nominee Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Nominee Name must contain only characters"
                                }
                              })} />
                              {errors.nominee_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.nominee_name.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="confirm_password">Nominee Age<sup>*</sup></label>
                              <input type="number" placeholder='Nominee Age'
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                  e.target.value = e.target.value.slice(0, 3);
                                }}
                                {...register('nominee_age', {
                                  required: "Nominee Age is required",
                                })} />
                              {errors.nominee_age && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.nominee_age.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="nominee">Relation With Applicant<sup>*</sup></label>
                              <div className='selectbox'>
                                <select name="relation" id="nominee" {...register('relation_with_applicant', {
                                  required: "Relation is required",
                                })}>
                                  <option value="">Select Nominee Relation</option>
                                  {
                                    relation?.map((item) => (
                                      <option value={item?.id} key={item?.id}>{item?.value}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              {errors.relation_with_applicant && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.relation_with_applicant.message}</p>}
                            </div>
                          </div>


                          <hr />
                          <h3 className="title">Add KYC Details</h3>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="gst_No">GST No.</label>
                              <input type="text" id='gst_No' maxLength={15} placeholder='29GGGGG1314R9Z6'
                                {...register('gst_no', {
                                  validate: validateGstNo,
                                })} />
                              {errors.gst_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.gst_no.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Pan_No">PAN No.<sup>*</sup></label>
                              <input type="text" id="Pan_No" maxLength={10} placeholder='ABCTY1234D'
                                {...register('pan', {
                                  required: "PAN No. is required",
                                  pattern: {
                                    value: /^[a/A-z/Z]{5}[0-9]{4}[a/A-z/Z]{1}$/, // Regular expression pattern for PAN card
                                    message: "Enter a valid PAN No."
                                  }
                                })}
                              />
                              {errors.pan && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.pan.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label >PAN Card<sup>*</sup></label>
                              <input type="file" multiple
                                accept=".jpg,.jpeg,.png,.bmp,.pdf"
                                {...register('pan_card', {
                                  required: "Upload PAN Card",
                                  onChange: handlePanChange
                                })} />
                              {errors.pan_card && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.pan_card.message}</p>}
                              {panerror && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{panerror}</p>}
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="selectId">Select ID Proof<sup>*</sup></label>
                              <div className='selectbox'>
                                <select id="selectId"  {...register('id_proof_id', {
                                  required: "Select ID Proof",
                                })}>
                                  <option value="">Select ID Proof</option>
                                  {
                                    id_proof?.map((item) =>
                                      <option value={item?.id} key={item?.id}>{item?.value}</option>
                                    )
                                  }

                                </select>
                              </div>
                              {errors.id_proof_id && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.id_proof_id.message}</p>}
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label >ID Proof<sup>*</sup></label>
                              <input type="file" multiple
                                accept=".jpg,.jpeg,.png,.bmp,.pdf"
                                {...register('id_proof', {
                                  required: "Upload ID Proof",
                                  onChange: handleIdProofChange
                                })} />
                              {errors.id_proof && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.id_proof.message}</p>}
                              {idprooferror && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{idprooferror}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="confirm_password">Cancel Cheque<sup>*</sup></label>
                              <input type="file"
                                accept=".jpg,.jpeg,.png,.bmp,.pdf"
                                {...register('cancel_cheque', {
                                  required: "Upload Cancel Cheque",
                                  onChange : handleCANCELCHECKChange
                                })} />
                              {errors.cancel_cheque && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.cancel_cheque.message}</p>}
                              {cancelcheckerror && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{cancelcheckerror}</p>}
                            </div>
                          </div>
                        </div>

                        <hr />

                        <h3 className="title">Add Bank Details</h3>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Bank Name">Bank Name<sup>*</sup></label>
                              <input type="text" id="Bank Name" placeholder='Bank Name'  {...register('bank_name', {
                                required: "Bank Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Bank Name must contain only characters"
                                }
                              })} />
                              {errors.bank_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_name.message}</p>}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Account_Type">Account Type<sup>*</sup></label>
                              <div className='selectbox'>
                                <select name="relation" id="nominee"
                                  {...register('account_type', {
                                    required: "Account Type is required",
                                  })}>
                                  <option value="">Select Account Type</option>
                                  <option value="1">Saving</option>
                                  <option value="2">Current</option>
                                </select>
                              </div>
                              {errors.account_type && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.account_type.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Account_Holder_Name">Account Holder Name<sup>*</sup></label>
                              <input type="text" id="Account_Holder_Name" placeholder='Account Holder Name' {...register('account_holder_name', {
                                required: "Account Holder Name is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Account Holder Name must contain only characters"
                                }
                              })} />
                              {errors.account_holder_name && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.account_holder_name.message}</p>}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Bank A/C No">Bank A/C No.<sup>*</sup></label>
                              <input type="number" id="Bank A/C No" placeholder='Bank A/C No'
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                  e.target.value = e.target.value.slice(0, 16);
                                }}
                                {...register('bank_account_number', {
                                  required: "Bank Account No. is required",
                                })} />
                              {errors.bank_account_number && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_account_number.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Branch_name">Branch Name & Address<sup>*</sup></label>
                              <input type="text" id="Branch_name" placeholder='Branch Name & Address'  {...register('branch_name_and_address', {
                                required: "Bank Branch Name is required",
                              })} />
                              {errors.branch_name_and_address && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.branch_name_and_address.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="IFSC_No">IFSC No.<sup>*</sup></label>
                              <input type="text" id="IFSC_No" maxLength={11} placeholder='SBIN0005943'   {...register('bank_ifsc_no', {
                                required: "IFSC No. is required",
                                pattern: {
                                  value: /^[A-Z]{4}0[A-Z0-9]{6}$/, // Regular expression pattern for IFSC code
                                  message: "Please enter a valid IFSC No."
                                }
                              })} />
                              {errors.bank_ifsc_no && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.bank_ifsc_no.message}</p>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-6">
                              <label htmlFor="Branch_City">Branch City<sup>*</sup></label>
                              <input type="text" id="Branch_City" placeholder='Branch City' {...register('branch_city', {
                                required: "Branch City is required",
                                pattern: {
                                  value: /^[A-Za-z][A-Za-z\s]*$/,
                                  message: "Branch City must contain only characters"
                                }
                              })} />
                              {errors.branch_city && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.branch_city.message}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-6">
                          <div className="form-check d-flex">
                            <input className="form-check-input" type="checkbox" id="agreed" {...register('agreed', {
                              required: "You must accept the Terms and Conditions"
                            })} />
                            <label className="form-check-label" htmlFor="agreed">
                              <p>I accept Smmartlife <u><Link onClick={scrollToTop} to="/Privacy-policy" >Privacy Policy</Link></u> and <u><Link onClick={scrollToTop} to="/term-condition" >Terms &amp; Conditions</Link></u>.</p>
                            </label>

                          </div>
                          {errors.agreed && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.agreed.message}</p>}
                        </div>
                        <div className="form-group mb-6">
                          <p className="desc mb-0">Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy. </p>
                        </div>
                        <div className="form-group d-flex">
                          <button className="btn ml-auto" type="submit">Register</button>
                        </div>
                      </form>
                    </div>
                    {loading && <div className="justify-content-center d-flex"><div className="loader"></div></div>}
                    {registerSuccess && <p className='mt-5 alert alert-success'> Thank You. Your account has been successfully registered with us.</p>}
                    {emailerr && <p className='mt-5 alert alert-danger' >{emailerr}</p>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Register;
