import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Modal, Form } from 'react-bootstrap'
import useRazorpay from "react-razorpay";
import { useForm } from 'react-hook-form'
import { scrollToTop } from '../sections/ScrollToTop';
import AxiosInstance from '../../Api/AxiosInstance';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Loader from './Loader';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Checkout = () => {

    const editAdderesvalidation = Yup.object().shape({
        first_name: Yup.string().matches(/^[A-Za-z ]+$/, "First Name must contain only characters").required('First Name is required').trim(),
        last_name: Yup.string().matches(/^[A-Za-z ]+$/, "Last Name must contain only characters").required('Last Name is required').trim(),
        street_address_1: Yup.string().required('Street Address is required'),
        pincode: Yup.string().required('Postcode is required').min(6, "Postcode must 6 digit").max(6, 'Postcode must 6 digit'),
        state_id: Yup.string().required('State is required'),
        city_id: Yup.string().required('City is required'),
        phone_no: Yup.string().required('Mobile No. is required')
            .matches(phoneRegExp, "Mobile No. must be 10 digit").min(10, "Mobile No. must be 10 digit").max(10, "Mobile No. must be 10 digit"),
        email: Yup.string().email("Invalid Email ID").required('Email ID is required'),
    })

    const [paymentMethod, setPaymentMethod] = useState("Bank Transfer")
    const [shipping, setShipping] = useState(30)
    const [cartdata, setCartdata] = useState([])
    let navigate = useNavigate();
    const [razorpay, isLoaded] = useRazorpay();
    const [payment_method_id, setPayment_method_id] = useState(2)
    const [cart_total_details, setCartDetail] = useState([])
    const { register, handleSubmit, formState: { errors }, setValue, getValues, clearErrors } = useForm();
    const [address, setAddress] = useState([])
    const [isCheck, setIsCheck] = useState(false)
    const [checkError, setCheckError] = useState(false)
    const { user } = JSON.parse(localStorage.getItem('user'));
    const [addressForm, setAddressForm] = useState(false)
    const [selectaddress, setSelectaddress] = useState('')
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [City, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [addres_id, setAddress_id] = useState('')
    const [shipping_address_id, setShipping_address_id] = useState('')
    const [ship_to_different_address, setShip_to_different_address] = useState(false)
    const [shippingError, setShippingError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [branch, setBranch] = useState([])
    const [branchId, setBranchId] = useState(1)
    const [paid_status, setPaid_status] = useState(1)
    const [gstloading, setGstloading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleUpdateAddress = async (value) => {
        setIsSubmitting(true);
        try {
            const response = await AxiosInstance.post('add-user-address', value);
            setAddressForm(false);
            setSelectedOption('');
            setSelectedCity('');
        } catch (error) {
            console.error("Error updating address:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        const fetchState = async () => {
            try {
                const response = await AxiosInstance.post('states')
                setOptions(response.data.data.states)
            } catch (error) {

            }
        }
        fetchState()
    }, []);

    const handleStateSelect = async (value, setFieldValue) => {
        try {
            const response = await AxiosInstance.post('cities', { state_id: value })
            setCity(response.data.data.cities)
            setSelectedOption('')
            setSelectedCity('')
            setFieldValue('city_id', '');

        } catch (error) {

        }
    };

    useEffect(() => {
        const getBrnach = async () => {
            try {
                const response = await AxiosInstance.post('get-branches')
                const sortedBranches = response.data.data.branches.sort((a, b) => a.id - b.id);
                setBranch(sortedBranches)
            } catch (error) {

            }
        }
        getBrnach()
    }, [])

    const handleSelectEditAddress = async (id) => {
        try {
            const response = await AxiosInstance.post('billing-address', { address_id: id })
            if (response.data.success) {
                setSelectaddress(response.data.data)
                setAddressForm(true)
                setSelectedCity(response.data.data.city_name)
                setSelectedOption(response.data.data.state_name)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        const address = async () => {
            try {
                const response = await AxiosInstance.post('billing-address-list', { id: `${user.id}` })
                setAddress(response.data.data)
            } catch (error) {

            }
        }
        address()
    }, [addressForm])

    useEffect(() => {
        if (address) {
            Object.entries(address).forEach(([key, value]) => {
                setValue(key, value);
            });
        }
    }, [address, setValue]);


    const uniqueCartIds = [...new Set(cartdata.map(item => item?.cart_id))];
    const cartId = uniqueCartIds.join(',');
    const fetchProduct = async () => {
        try {
            setGstloading(true)
            const response = await AxiosInstance.post('cart-lists', { billing_address_id: addres_id })
            setCartdata(response.data.data.items)
            setCartDetail(response.data.data.cart_total_details)
            setGstloading(false)
            setLoading(false)
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchProduct()
    }, [addres_id])


    const handleClose = () => {
        setAddressForm(false)
        setSelectedOption('')
        setSelectedCity('')
        setSelectaddress('')
    }


    const handlePayment = async (amount) => {
        if (ship_to_different_address == false) {
            setShipping_address_id('')
        }

        try {
            if (isCheck) {
                if (!addres_id) {
                    setAddressError(true)
                    toast.error('Please select billing address')
                    return;
                }

                if (ship_to_different_address == true && !shipping_address_id) {
                    setShippingError(true)
                    return;
                }


                const orderetail = await AxiosInstance.post('add-order-details',
                    {
                        total_amount: cart_total_details?.total,
                        sub_total: cart_total_details?.sub_total,
                        cart_id: cartId,
                        sgst: cart_total_details?.sgst,
                        cgst: cart_total_details?.cgst,
                        igst: cart_total_details?.igst,
                        address_id: addres_id,
                        ship_to_different_address: ship_to_different_address ? 1 : 0,
                        shipping_address_id: shipping_address_id,
                        branch_id: branchId,
                        paid_status: paid_status
                    })
                if (orderetail?.data?.success) {
                    setLoading(true)
                    if (payment_method_id == 2) {
                        AxiosInstance.post("add-payment-details", { order_id: orderetail.data.data.id, amount: amount, payment_method_id: payment_method_id })
                            .then((response) => {
                                if (response.status == 200) {
                                    document.dispatchEvent(new Event('itemAddedToCart'));
                                    setTimeout(() => {
                                        setLoading(false)
                                        navigate(`/ThankYou/${orderetail.data.data.id}`)
                                        scrollToTop()
                                    }, 500);
                                } else if (response.status === 400 && response.message === "Token is expired!!") {
                                    scrollToTop()
                                    navigate("/login");
                                    localStorage.removeItem('user')
                                    localStorage.removeItem('token')
                                }
                            }).catch((err) => console.log(err));
                    } else {
                        const response = await AxiosInstance.post('create-order-id-in-razorpay', { amount: amount })
                        const orderId = response.data.data.order_id
                        if (response.data.success === 1) {
                            const razorpay_key = process.env.RAZORPAY_KEY_ID;
                            const options = {
                                key: 'rzp_test_3PYKIWLbUWhl67',
                                accept_partial: false,
                                amount: Math.round(amount * 100),
                                currency: "INR",
                                name: "Test",
                                description: "Test Transaction",
                                order_id: orderId,
                                handler: (res) => {
                                    if (res.razorpay_signature) {
                                        AxiosInstance.post("add-payment-details", { order_id: orderetail.data.data.id, payment_id: res.razorpay_payment_id, amount: amount, payment_method_id: payment_method_id })
                                            .then((response) => {
                                                if (response.status == 200) {
                                                    document.dispatchEvent(new Event('itemAddedToCart'));
                                                    setTimeout(() => {
                                                        setLoading(false)
                                                        navigate(`/ThankYou/${orderetail.data.data.id}`)
                                                        scrollToTop()
                                                    }, 500);
                                                } else if (response.status === 400 && response.message === "Token is expired!!") {
                                                    navigate("/login");
                                                    localStorage.removeItem('user')
                                                    localStorage.removeItem('token')
                                                }
                                            }).catch((err) => console.log(err));
                                    }
                                },
                                notes: {
                                    address_line_1: "Razorpay Corporate Office",
                                },
                                theme: {
                                    color: "#3399cc",
                                },
                            };

                            const rzpay = new razorpay(options);
                            rzpay.open();
                        } else {
                            setLoading(false)
                            return false;
                        }
                    }
                }

            }
            else {
                setLoading(false)
                setCheckError(true)
            }

        } catch (error) {
            setLoading(false)
            console.error("Error while handling payment:", error);
            if (error.response.data.message == "Unauthenticated.") {
                navigate('/login')
            }
        }
    };

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <div className="wrapper">

                        <div className="blank_header trans"></div>
                        <main className="main-content">
                            <nav aria-label="breadcrumb" className="breadcrumb-style1 pt-5 pb-5">
                                <div className="container">
                                    <ol className="breadcrumb justify-content-center mb-0">
                                        <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                                    </ol>
                                </div>
                            </nav>

                            {cartdata.length ?

                                <section className="shopping-checkout-wrap section-space">
                                    <div className="container">
                                    
                                        <div className="row checkout-form billing-address">
                                            <div className="col-lg-6">
                                                <div className="checkout-billing-details-wrap">
                                                    <div className='box-title'>
                                                        <h3>Select Billing Address</h3>
                                                    </div>
                                                    <div className='form-inner d-flex'>
                                                        {address?.map((u, index) =>
                                                            <Form.Group className='input-field full-width relative' key={u?.id}>
                                                                <label className='block-radiobox'>
                                                                    <Form.Control type='radio' name="address" value={u?.id}
                                                                        onChange={() => { setAddress_id(u?.id); setAddressError(false); setIsCheck(false); setCheckError(false) }}
                                                                    />
                                                                    <div className='card-address trans' key={index} >
                                                                        <h4>{u?.first_name} {u?.last_name}</h4>
                                                                        <p>{u?.street_address_1} , {u?.street_address_2 && `${u?.street_address_2} ,`} {u?.city_name} , {u?.state_name} , {u?.pincode}  </p>
                                                                    </div>
                                                                </label>
                                                                <button className='edit-add-btn' onClick={() => { handleSelectEditAddress(u?.id) }}>
                                                                    <i className='fa fa-pencil'></i>
                                                                </button>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                    <div id="CheckoutBillingAccordion2" className="col-md-12">
                                                        <div className="checkout-box-width checkout-box mb-1" data-bs-toggle="collapse" data-bs-target="#CheckoutTwo" aria-expanded="false" role="toolbar">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input visually-hidden" id="ship-to-different-address" onChange={() => { setShip_to_different_address(!ship_to_different_address); setShippingError(false) }} />
                                                                <label className="custom-control-label" htmlFor="ship-to-different-address">Ship to a different address?</label>
                                                            </div>
                                                        </div>
                                                        <div id="CheckoutTwo" className="collapse" data-bs-parent="#CheckoutBillingAccordion2">
                                                            <div className='box-title mt-2'>
                                                                <h3>Select Shipping Address</h3>
                                                            </div>
                                                            <div className='form-inner d-flex'>
                                                                {address?.map((u, index) =>
                                                                    <Form.Group className='input-field full-width relative' key={u?.id}>
                                                                        <label className='block-radiobox'>
                                                                            <Form.Control type='radio' name="ygygyg" value={u?.id}
                                                                                onChange={() => { setShipping_address_id(u?.id); setShippingError(false) }}
                                                                            />
                                                                            <div className='card-address trans' key={index} >
                                                                                <h4>{u?.first_name} {u?.last_name}</h4>
                                                                                <p>{u?.street_address_1} , {u?.street_address_2 && `${u?.street_address_2} ,`} {u?.city_name} , {u?.state_name} , {u?.pincode}  </p>
                                                                            </div>
                                                                        </label>
                                                                        <button className='edit-add-btn' onClick={() => handleSelectEditAddress(u?.id)}>
                                                                            <i className='fa fa-pencil'></i>
                                                                        </button>
                                                                    </Form.Group>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className='btn mt-2' onClick={() => { setAddressForm(true); setSelectaddress('') }}>Add New Address</button>
                                                    {shippingError && <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>Please select shipping address or uncheck Ship to a different address?</p>}
                                                </div>
                                            </div>
                                            {
                                                gstloading ?
                                                    <div className="col-lg-6">
                                                        <div className="checkout-order-details-wrap h-100 relative">
                                                            <div className="justify-content-center d-flex h-100"><div className="loader"></div></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-6">
                                                        <div className="checkout-order-details-wrap">
                                                            <div className="order-details-table-wrap table-responsive">
                                                                <h2 className="title mb-25">Your order</h2>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="product-name">Product</th>
                                                                            <th className="product-total">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-body">
                                                                        {cartdata?.map((item, index) =>
                                                                        (<tr className="cart-item" key={index}>
                                                                            <td className="product-name">{item?.title} {item?.variant_name && (item?.variant_name)} <span className="product-quantity">× {item?.quantity}</span></td>
                                                                            <td className="product-total">₹{item?.sub_total?.toFixed(2)}</td>
                                                                        </tr>
                                                                        ))}
                                                                    </tbody>
                                                                    <tfoot className="table-foot">
                                                                        <tr className="cart-subtotal">
                                                                            <th>Subtotal</th>
                                                                            <td>₹{cart_total_details?.sub_total?.toFixed(2)}</td>
                                                                        </tr>
                                                                        <tr className="shipping">
                                                                            <th>Tax</th>
                                                                            {cart_total_details?.igst_status == 1 ?
                                                                                <td>IGST: ₹{cart_total_details?.igst?.toFixed(2)}</td>
                                                                                :
                                                                                <>
                                                                                    <td>
                                                                                        <div className='gst-text'>SGST: ₹{cart_total_details?.cgst?.toFixed(2)}</div>
                                                                                        <div className='gst-text'>CGST: ₹{cart_total_details?.sgst?.toFixed(2)}</div>
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                        <tr className="order-total">
                                                                            <th><strong>Total</strong></th>
                                                                            <td><strong>₹{cart_total_details?.total.toFixed(2)}</strong></td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>


                                                                <div className="shop-payment-method">

                                                                    <div className='w-100'>
                                                                        <input type='radio' name='payment' value='2' id='CashOnDelivery' defaultChecked />
                                                                        <label className='pl-2' htmlFor='CashOnDelivery'>Pickup Appointment</label>
                                                                        {payment_method_id == 2 &&
                                                                            <div className="form-group d-flex v-center pickup-appoi">
                                                                                <div className='selectbox '>
                                                                                    <select name="state_id" id="state"
                                                                                        onChange={(e) => {
                                                                                            setBranchId(e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            branch?.map((item) => (
                                                                                                <option value={item?.id} key={item?.id}>{item?.branch_name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <p className="p-text">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <Link onClick={scrollToTop} to="/Privacy-policy">privacy policy.</Link></p>
                                                                    <div className="agree-policy">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="privacy"
                                                                                className="custom-control-input visually-hidden"
                                                                                onChange={() => { setIsCheck(!isCheck); setCheckError(false) }}
                                                                            />
                                                                            <label htmlFor="privacy" className="custom-control-label">I have read and agree to the website <Link to='/term-condition'> terms and conditions. </Link><span className="required">*</span></label>
                                                                            {checkError && <p style={{ color: 'red', fontSize: '13px' }}>You must agree to the Terms & Conditions</p>}
                                                                        </div>
                                                                    </div>
                                                                    <Link onClick={() => { handlePayment(cart_total_details?.total) }} className="btn-place-order">Place order</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </section >
                                :
                                <div >
                                    <br />
                                    <h3 className='text-center mt-3'>Your cart is empty</h3>
                                    <br />
                                    <div className="text-center">
                                        <button onClick={() => navigate('/shop')} className="btn ml-auto" >Return to Shop</button>
                                    </div>
                                    <br />
                                </div>
                            }
                        </main >
                        <Modal show={addressForm} onHide={handleClose}>
                            <button type="button" className="btn-close" >
                                <span className="fa fa-close" onClick={handleClose}></span>
                            </button>
                            <div className=" m-3 my-account-form">
                                <div className="mb-2">
                                    <h4>Account Details</h4>
                                </div>
                                <Formik
                                    initialValues={{
                                        id: selectaddress ? selectaddress.id : '',
                                        first_name: selectaddress ? selectaddress?.first_name : "",
                                        company_name: selectaddress ? selectaddress?.company_name : "",
                                        last_name: selectaddress ? selectaddress?.last_name : "",
                                        street_address_2: selectaddress?.street_address_2 == "null" ? "" : selectaddress?.street_address_2,
                                        street_address_1: selectaddress ? selectaddress?.street_address_1 : "",
                                        state_id: selectaddress?.state_id,
                                        pincode: selectaddress ? selectaddress?.pincode : "",
                                        city_id: selectaddress?.city_id,
                                        phone_no: selectaddress ? selectaddress?.phone_no : "",
                                        email: selectaddress ? selectaddress?.email : ""
                                    }}
                                    validationSchema={editAdderesvalidation}
                                    onSubmit={handleUpdateAddress}>
                                    {({ handleSubmit, values, handleChange, setFieldValue }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="f_name">First Name <abbr className="required" title="required">*</abbr></label>
                                                        <input name='first_name' onChange={handleChange} id="f_name" placeholder='Enter First Name' type="text" className="form-control"
                                                            value={values.first_name}
                                                        />
                                                        <ErrorMessage name="first_name" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="l_name">Last Name <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="l_name" placeholder='Enter Last Name' type="text" className="form-control"
                                                            value={values.last_name} name='last_name' />
                                                        <ErrorMessage name="last_name" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="com_name">Company Name (optional)</label>
                                                        <input onChange={handleChange} id="com_name" name='company_name' placeholder='Enter Company Name' type="text"
                                                            className="form-control" value={values.company_name} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="street-address">Street address <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="street-address" type="text" className="form-control " placeholder="House No. and Street Name"
                                                            value={values.street_address_1} name='street_address_1' />
                                                        <ErrorMessage name="street_address_1" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="street-address2" className="visually-hidden">Street address 2 <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="street-address2" type="text" className="form-control" placeholder="Apartment, Suite, Unit etc. (Optional)"
                                                            value={values.street_address_2} name='street_address_2' />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="nominee">State<sup>*</sup></label>
                                                        <div className='selectbox'>
                                                            <select name="state_id" id="state"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleStateSelect(e.target.value, setFieldValue);
                                                                }}
                                                            >
                                                                <option value=''>{selectedOption ? selectedOption : "Select State"}</option>
                                                                {
                                                                    options?.map((item) => (
                                                                        <option value={item?.id} key={item?.id}>{item?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <ErrorMessage name="state_id" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="form-grou">
                                                        <label htmlFor="nominee">State<sup>*</sup></label>
                                                        <div className='selectbox'>
                                                            <select name="city_id" id="city"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                }}>
                                                                <option value=''>{selectedCity ? selectedCity : "Select City"}</option>
                                                                {
                                                                    City?.map((item) => (
                                                                        <option value={item?.id} key={item?.id}>{item?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <ErrorMessage name="city_id" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="pz-code">Postcode / ZIP <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="pz-code" placeholder='Enter Postcode' type="number" className="form-control"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                                e.target.value = e.target.value.slice(0, 6);
                                                            }} value={values.pincode} name='pincode' />
                                                        <ErrorMessage name="pincode" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="phone">Mobile No. <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="phone" placeholder='Enter Mobile No.' type="number" className="form-control"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                                e.target.value = e.target.value.slice(0, 10);
                                                            }} value={values.phone_no} name='phone_no' />
                                                        <ErrorMessage name="phone_no" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email ID <abbr className="required" title="required">*</abbr></label>
                                                        <input onChange={handleChange} id="email" type="email" placeholder='Enter Email ID' className="form-control"
                                                            value={values.email} name='email' />
                                                        <ErrorMessage name="email" >
                                                            {msg => (
                                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{msg}</p>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 d-flex">
                                                    <button type='submit' className="btn ml-auto" disabled={isSubmitting}>Save Address</button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </Modal>
                    </div>
            }
        </>
    )
}
export default Checkout