import React, { useState, useEffect } from 'react'
import Loader from './Loader';
import Product from '../sections/Product'
import banner_text from "../../images/banner_text.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useParams } from 'react-router-dom'
import { Autoplay, Navigation, Virtual } from 'swiper/modules';
import { Tooltip } from 'antd';
import { scrollToTop } from '../sections/ScrollToTop';
import AxiosInstance from '../../Api/AxiosInstance';
import { useAuth } from '../../Context/AuthContext';



const Dashboard = () => {
    const params = useParams()
    const { loggedIn, guestId, setGuestUserId } = useAuth(); // Assuming you have a useAuth hook to access authentication state
    const [loading, setLoading] = useState(true);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    useEffect(() => {
        setGuestUserId(); // Call setGuestUserId when the component mounts
    }, []);
    const [SliderData, setSliderData] = useState([])
    const [ContactUsDetailsData, setContactUsDetailsData] = useState([])
    const [CTAData, setCTAData] = useState([])
    const [new_products, setNewProductData] = useState([])
    const [recomended_products, setRecomendedProducts] = useState([])
    const [categories, setCategories] = useState([])


    useEffect(() => {
        const fetchHomePageData = async () => {
            try {
                const response = await AxiosInstance.post('home-page')
                setSliderData(response.data.data.sliders)
                window.contact_us_details = response.data.data.contact_us_details;
                setContactUsDetailsData(response.data.data.contact_us_details)
                setCTAData(response.data.data.cta_sections)
                setCategories(response.data.data.categories)
                setNewProductData(response.data.data.new_products)
                setRecomendedProducts(response.data.data.recomended_products)
                setLoading(false); // Set loading to false after the delay
            } catch (error) {

            }
        }
        fetchHomePageData()
    }, [])



    const fetchData = async () => {
        try {
            const response = await AxiosInstance.post('home-page')
            setNewProductData(response.data.data.new_products)
            setRecomendedProducts(response.data.data.recomended_products)
        } catch (error) {

        }
    }


    useEffect(() => {
        fetchData()
    }, [params])


    return (
        <>
            {loading ? (
                <Loader />
            ) : (

                <div className="wrapper">
                    <main className="main-content">
                        <section className="hero-slider-area position-relative">
                            <div className="swiper hero-slider-container">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        autoplay={{
                                            delay: 10000,
                                            disableOnInteraction: false,
                                        }}
                                        rewind={true}
                                        modules={[Autoplay]}
                                        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
                                    >
                                        {SliderData?.map((item, idx) => (
                                            <SwiperSlide key={idx}>
                                                <div className={activeSlideIndex === idx ? 'swiper-slide hero-slide-item swiper-slide-active' : 'swiper-slide hero-slide-item'}>
                                                    <div className="container">
                                                        <div className="row align-items-center position-relative">
                                                            <div className="col-12 col-md-6">
                                                                <div className="hero-slide-content">
                                                                    <div className="hero-slide-text-img">Smmartlife</div>
                                                                    <h2 className="hero-slide-title">{item?.title}</h2>
                                                                    <p className="hero-slide-desc">{item?.short_description}</p>
                                                                    <a className="btn" href="/shop">BUY NOW</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="hero-slide-thumb">
                                                                    <img src={item?.file_url} width="841" height="832" alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="hero-slide-text-shape">
                                                        <img src={banner_text} width="70" height="955" alt="Image" />
                                                    </div>
                                                    <div className="hero-slide-social-shape"></div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>


                                </div>
                                <div className="hero-slider-pagination swiper-pagination-fraction swiper-pagination-horizontal">
                                    <span className="swiper-pagination-current">{activeSlideIndex + 1}</span> / <span className="swiper-pagination-total">{SliderData.length}</span>
                                </div>
                            </div>

                            <div className="hero-slide-social-media">
                                <Tooltip placement="bottom" title={"Twitter"} style={{ display: 'block', width: 700, padding: 30 }}>
                                    <a href={ContactUsDetailsData?.tweeter_link ? ContactUsDetailsData?.tweeter_link : '#'} target="_blank"><i className="fa fa-twitter"></i></a>
                                </Tooltip>

                                <Tooltip placement="bottom" title={"Facebook"}>
                                    <a href={ContactUsDetailsData?.facebook_link ? ContactUsDetailsData?.facebook_link : '#'} target="_blank"><i className="fa fa-facebook"></i></a>
                                </Tooltip>

                                <Tooltip placement="bottom" title={"Instagram"}>
                                    <a href={ContactUsDetailsData?.insta_link ? ContactUsDetailsData?.insta_link : '#'} target="_blank"><i className="fa fa-instagram"></i></a>
                                </Tooltip>

                                <Tooltip placement="bottom" title={"Youtube"}>
                                    <a href={ContactUsDetailsData?.youtube_link ? ContactUsDetailsData?.youtube_link : '#'} target="_blank"><i className="fa fa-youtube"></i></a>
                                </Tooltip>
                            </div>
                        </section>
                        {/* Banner Section End*/}
                        {/* categories Section Start*/}
                        <section className="front-cate-section section-space categories">
                            <div className="container">
                                <div className="prod-slide-front g-3 g-sm-6">
                                    <Swiper className="mySwiper"
                                        modules={[Virtual, Navigation, Autoplay]}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        rewind={true}
                                        autoplay={{
                                            delay: 10000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 2,
                                            },
                                            992: {
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                slidesPerView: 6,
                                            },
                                        }}

                                    >
                                        {categories?.map((item, idx) => (
                                            <SwiperSlide key={idx}>
                                                <div className="category_items">
                                                    <div className="product-category-item">
                                                        <Link onClick={scrollToTop} to={`/product-category/${item?.slug}`}>
                                                            <img className="icon" src={item?.file_url} width="70" height="80" alt="" />
                                                            <h3 className="title">{item?.name}</h3>
                                                        </Link>
                                                        {item?.is_new == 1 ? '<span className="flag-new">new</span>' : ''}
                                                        {item?.on_sale == 1 ? '<span style={{ backgroundColor: "#835BF4" }} className="flag-new">sale</span>' : ''}

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </section>
                        {/* categories Section End*/}

                        {/* Recommended Product Section start*/}
                        <Product title="Recommended for You" product_data={recomended_products} field="recommended_desc" contact_us_details={ContactUsDetailsData} />
                        {/* Recommended Product Section End*/}

                        {/* CTA Section Start*/}
                        {
                            CTAData?.length > 0 &&
                            <section className="offer-banner-section section-space bg-gray">
                                <div className="container">
                                    <div className="row">
                                        {CTAData?.map((item, idx) => (
                                            <div className="col-sm-6 col-lg-4" key={idx}>
                                                <div className="product-banner-item">
                                                    <img src={item?.file_url} width="370" height="370" alt="" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        }

                        <Product title="New Arrivals" product_data={new_products} field="new_arrivals_desc" contact_us_details={ContactUsDetailsData} />

                    </main>
                </div>
            )}
        </>
    )
}

export default Dashboard