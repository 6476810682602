import React, { useEffect, useState } from 'react'
import AxiosInstance from '../../Api/AxiosInstance'
const LegalDocument = () => {

    const [data, setData] = useState()

    const fetchCms = async () => {
        try {
            const response = await AxiosInstance.post('get-page/legal-document')
            setData(response.data.data.page.description)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchCms()
    }, [])
    return (
        <div>
            <div className="blank_header trans"></div>

            <section className="cosmetic-section ptb-40 entry-content">
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: data }}></div>
                </div>
            </section>
        </div>
    )
}


export default LegalDocument
