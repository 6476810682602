import React, { useEffect, useState } from 'react'
import Product from '../sections/Product'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Pagination from './Pagination'
import AxiosInstance from '../../Api/AxiosInstance';
import { scrollToTop } from '../sections/ScrollToTop'
import Loader from './Loader'

const Shop = () => {
    const params = useParams()
    const [product, setProduct] = useState([])
    const navigate = useNavigate()
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000); // Set an initial maximum price
    const [selectedPrice, setSelectedPrice] = useState(maxPrice);
    const [category, setCategory] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(16);
    const [filterProduct, setFilterProduct] = useState([])
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState('')

    const handlePriceChange = (e) => {
        const newMaxPrice = e.target.value
        setSelectedPrice(newMaxPrice);
        const filteredProducts = product.filter(product => product.price - product.discounted_price <= newMaxPrice);
        setFilterProduct(filteredProducts);
    };


    useEffect(() => {
        const fetchCategory = async () => {
            try {
                setLoading(true)
                const response = await AxiosInstance.post("categories")
                setCategory(response.data.data)
                setLoading(false)
            } catch (error) {
            }
        }
        fetchCategory()
    }, [])

    const handleChange = (e) => {
        const value = e.target.value;
        if (!value) {
            navigate('/shop')
        } else {
            navigate(`/product-category/${value}`);
        }
    };

    const fetchProduct = async () => {
        try {
            setLoading(true)
            if (params.slug !== undefined && params.slug !== '') {
                const response = await AxiosInstance.post(`products/${params.slug}`, { filter: filter })
                setFilterProduct(response.data.data)
                setProduct(response.data.data)
                const productData = response.data.data
                if (productData.length > 0) {
                    const prices = productData?.map(product => product.price - product.discounted_price);
                    const maxPrice = Math.max(...prices);
                    const minPrice = Math.min(...prices);

                    setMaxPrice(maxPrice);
                    setMinPrice(minPrice);
                    setSelectedPrice(maxPrice);
                }
            } else {
                const response = await AxiosInstance.post('products', { filter: filter })
                setProduct(response.data.data)
                setFilterProduct(response.data.data)
                const productData = response.data.data
                if (productData.length > 0) {
                    const prices = productData?.map(product => product.price - product.discounted_price);
                    const maxPrice = Math.max(...prices);
                    const minPrice = Math.min(...prices);

                    setMaxPrice(maxPrice);
                    setMinPrice(minPrice);
                    setSelectedPrice(maxPrice);
                }
            }
            setLoading(false)
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchProduct()
    }, [params.slug, filter])

    const numberOfPage = Math.ceil(filterProduct.length / itemsPerPage);
    const pageIndex = Array.from({ length: numberOfPage }, (_, idx) => idx + 1);
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.from(filterProduct).slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <section className="page-header-area pt-10 pb-9" style={{ backgroundColor: '#FFF3DA' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="page-header-st3-content text-center text-md-start">
                                        <ol className="breadcrumb justify-content-center justify-content-md-start">
                                            <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                                            <li className="breadcrumb-item active text-dark" aria-current="page">Products</li>
                                        </ol>
                                        <h2 className="page-header-title">All Products</h2>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <h5 className="showing-pagination-results mt-5 mt-md-9 text-center text-md-end">Showing {currentItems.length} Results</h5>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="shop-top-bar-area">
                        <div className="container">
                            <div className="shop-top-bar">
                                <div className="selectbox cate-sort-select">
                                    <select onChange={handleChange}>
                                        <option data-display="Categories" value=''>Categories</option>
                                        {category?.map((category) => (
                                            <option value={category?.slug} key={category?.id}>{category?.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="select-price-range">
                                    <h4 className="title">Pricing:</h4>
                                    <div className="select-price-range-slider">
                                        <input type="range" min={minPrice} max={maxPrice} value={selectedPrice} onChange={handlePriceChange} className="noUi-horizontal noUi-background" />
                                        <div className="slider-labels">
                                            <span id="slider-range-value1">₹{minPrice}</span>
                                            <span>-</span>
                                            <span id="slider-range-value2">₹{selectedPrice}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="select-on-sorting d-flex">
                                    <div className="sorting-inn d-flex selectbox cate-sort-select">
                                        <select onChange={(e) => setFilter(e.target.value)}>
                                            <option value="">Filter</option>
                                            <option value="ASC">Price: Low to High</option>
                                            <option value="DESC">Price: High to Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loading ?
                            <Loader /> :
                            (
                                <>
                                    <Product title="" product_data={currentItems} field="" />
                                    {
                                        !pageIndex[1] ? <></> :
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={Math.ceil(product.length / itemsPerPage)}
                                                onPageChange={onPageChange}
                                                pageIndex={pageIndex}
                                            />
                                    }
                                </>
                            )
                    }

                </main>
            </div>
        </>
    )
}

export default Shop
