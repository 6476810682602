// AuthContext.js
import React, { createContext, useContext, useState  } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const getToken = () => {
  return localStorage.getItem('token') || ''; // Provide a default value if no token is found
};
export const getGuestId = () => {
  return Cookies.get('random_id') || ''; // Provide a default value if no token is found
};

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem('user')); 
  const [user, setUser] = useState(localStorage.getItem('user')); 
  const [guestId, setGuestId] = useState(Cookies.get('random_id') || '');  

  const handleLogin = () => {
    setUser(localStorage.getItem('user'));
    setLoggedIn(true); 
  };

  const handleLogout = () => { 
    setLoggedIn(false);
  };
  const setGuestUserId = () => {
    if (!loggedIn && guestId == "") { 
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 5; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      Cookies.set('random_id', result, { expires: 7 });
      setGuestId(result); // Assuming setGuestId is a state setter function
    }
  };
  
  return (
    <AuthContext.Provider value={{ loggedIn, handleLogin, handleLogout, guestId, user, getToken, getGuestId, setGuestUserId  }}>
      {children}
    </AuthContext.Provider>
  );
};
