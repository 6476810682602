import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import AxiosInstance from '../../Api/AxiosInstance';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from 'react-toastify';

const ChangePassword = () => {
    const [passwordType, setPasswordType] = useState("password");
    const [newpasswordType, setNewpasswordType] = useState("password")
    const [confirmpasswordType, setConfirmpasswordType] = useState("password");

    const togglePassword = (id) => {
        if (id == "currentpassword") {
            if (passwordType === "password") {
                setPasswordType("text");
                return true;
            }
            setPasswordType("password");
            return true;
        }
        else if (id == "confirmpassword") {
            if (confirmpasswordType === "password") {
                setConfirmpasswordType("text");
                return true;
            }
            setConfirmpasswordType("password");
            return true;
        } else {
            if (newpasswordType === "password") {
                setNewpasswordType("text");
                return true;
            }
            setNewpasswordType("password");
            return true;
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const submit = async (data) => {
        try {
            const response = await AxiosInstance.post('change-password', data)
            if (response.data.success) {
                toast.success("Password updated successfully,")
            }

        } catch (error) {

        }
    }
    return (
        <>
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <section className="page-header-area pt-5 pb-5" style={{ backgroundColor: "#FFF3DA" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="page-header-st3-content text-center text-md-start">
                                        <ol className="breadcrumb justify-content-center justify-content-md-start mb-0">
                                            <li className="breadcrumb-item"><Link className="text-dark" onClick={scrollToTop} to="/">Home</Link></li>
                                            <li className="breadcrumb-item active text-dark" aria-current="page">Change Password</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-space">
                        <div className="container">
                            <div className="row mb-n8">
                                <div className="col-lg-3 mb-2"></div>
                                <div className="col-lg-6 mb-8">
                                    <div className="my-account-item-wrap">
                                        <h3 className="title">Update Password</h3>
                                        <div className="my-account-form">
                                            <form onSubmit={handleSubmit(submit)}>
                                                <div className="form-group mb-6 pwd-eye">
                                                    <label htmlFor="login_pwsd">Current Password <sup>*</sup></label>
                                                    <input
                                                        type={passwordType}
                                                        maxLength={20}
                                                        id="login_pwsd"
                                                        {...register('current_password', {
                                                            required: "Current Password is required",
                                                            minLength: { value: 6, message: "Current Password must be at least 6 characters long" }
                                                        })}
                                                    />
                                                    <div onClick={() => togglePassword("currentpassword")}>
                                                        {passwordType === "password" ? (
                                                            <AiOutlineEye className='pass-view' />

                                                        ) : (

                                                            <AiOutlineEyeInvisible className='pass-view' />
                                                        )}
                                                    </div>

                                                    <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.current_password?.message}</p>
                                                </div>


                                                <div className="form-group mb-6 pwd-eye">
                                                    <label htmlFor="login_pwsd">New Password <sup>*</sup></label>
                                                    <input
                                                        type={newpasswordType}
                                                        maxLength={20}
                                                        id="login_pwsd"
                                                        {...register('password', {
                                                            required: "New Password is required",
                                                            minLength: { value: 6, message: "New Password must be at least 6 characters long" }
                                                        })}
                                                    />
                                                    <div onClick={() => togglePassword("newpassword")}>
                                                        {newpasswordType === "password" ? (
                                                            <AiOutlineEye className='pass-view' />

                                                        ) : (

                                                            <AiOutlineEyeInvisible className='pass-view' />
                                                        )}
                                                    </div>

                                                    <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.password?.message}</p>
                                                </div>


                                                <div className="form-group mb-6 pwd-eye">
                                                    <label htmlFor="login_pwsd">Confirm New Password <sup>*</sup></label>
                                                    <input
                                                        type={confirmpasswordType}
                                                        maxLength={20}
                                                        id="login_pwsd"
                                                        {...register('confirm_password', {
                                                            required: "Confirm Password is required",
                                                            minLength: { value: 6, message: "confirm Password must be at least 6 characters long" },
                                                            validate: value => value === watch('password') || "Confirm new password do not match"
                                                        })}
                                                    />
                                                    <div onClick={() => togglePassword("confirmpassword")}>
                                                        {confirmpasswordType === "password" ? (
                                                            <AiOutlineEye className='pass-view' />

                                                        ) : (

                                                            <AiOutlineEyeInvisible className='pass-view' />
                                                        )}
                                                    </div>

                                                    <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.confirm_password?.message}</p>
                                                </div>
                                                <div className="form-group d-flex align-items-center mb-14">
                                                    <button className="btn ml-auto" type="submit">Update Password</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    )
}

export default ChangePassword
